import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useLanguage } from "../../context";
import {
    getCookie,
    languageStorage,
    setLocalStorage,
} from "../../helpers/localStorage";
import {
    getUrlOpposite,
    getUrlOppositeActivityDetails,
} from "../../helpers/utils";

const SignInLink = ({ isMobile = false, closeMenu = null }) => {
    const { language, updateLanguage } = useLanguage();
    const intl = useIntl();
    const { pathname, state } = useLocation();
    const token = getCookie("token");
    let history = useNavigate();

    const english = intl.formatMessage({ id: "english" });
    const french = intl.formatMessage({ id: "french" });
    let urlOpposite = null;

    const changeLanguage = (value) => {
        setLocalStorage(languageStorage, value);
        updateLanguage();
        // console.log("location pathname --- ", location.pathname);
        if (
            `${pathname}`.includes("activity-details") ||
            `${pathname}`.includes("details-activite")
        ) {
            urlOpposite = getUrlOppositeActivityDetails(pathname);
        } else {
            urlOpposite = getUrlOpposite(pathname);
        }
        history(urlOpposite, {
            replace: true,
            state: state,
        });
    };

    return (
        <div
            className={`flex gap-4 ${isMobile ? "flex-col" : "flex-row items-center"
                }`}
        >
            <NavLink
                to={`${intl.formatMessage({ id: "link.contact.us" })}`}
                onClick={isMobile && closeMenu}
                className={`text-base font-semibold ${location.pathname === intl.formatMessage({ id: "link.contact.us" })
                        ? "border-secondary text-light border-b-2"
                        : "hover:text-light"
                    } `}
            >
                <FormattedMessage id="contact.us" />
            </NavLink>

            {token ? (
                <>
                    <NavLink
                        onClick={isMobile && closeMenu}
                        to={`${intl.formatMessage({ id: "link.subscription" })}`}
                        className={`flex text-base font-semibold ${location.pathname ===
                                intl.formatMessage({ id: "link.subscription" })
                                ? "border-secondary text-light border-b-2"
                                : "hover:text-light"
                            } `}
                    >
                        <FormattedMessage id="subscription" />
                    </NavLink>
                </>
            ) : (
                <>
                    <NavLink
                        onClick={isMobile && closeMenu}
                        to={`${intl.formatMessage({ id: "link.sign.in" })}`}
                        className={`text-base font-semibold ${location.pathname === intl.formatMessage({ id: "link.sign.in" })
                                ? "border-secondary text-light border-b-2"
                                : "hover:text-light"
                            } `}
                    >
                        <FormattedMessage id="sign.in" />
                    </NavLink>
                </>
            )}
            <select
                className="form-select rounded-md text-black text-base font-semibold cursor-pointer focus:outline-none focus:ring-secondary focus:border-secondary focus:z-10"
                value={language}
                onChange={(e) => changeLanguage(e.target.value)}
            >
                <option value="en">{english}</option>
                <option value="fr">{french}</option>
            </select>
        </div>
    );
};

export default SignInLink;
