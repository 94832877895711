import { UPDATE_SIGNUP } from "../constants";

const initialState = null;

export const setSignUpData = (data) => ({
    type: UPDATE_SIGNUP,
    data,
});

export const signup = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_SIGNUP:
            return action.data;
        default:
            return state;
    }
};
