import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import React, { Fragment } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { NavLink, useLocation } from "react-router-dom";
import { logo } from "../../assets/images";
import { pageSignIn, pages } from "../../constants/pages";
import SignInLink from "./SignInLink";
import { disconnect, getCookie } from "../../helpers/localStorage";
import Avatar from "react-avatar";

const MobileMenu = ({ setOpen, closeMenu, open, showRightLink }) => {
    // console.log("open ---", open);
    const location = useLocation();
    const intl = useIntl();

    const onLogOut = () => {
        disconnect();
        window.location.reload();
    };

    return (
        <Fragment>
            <Transition.Root show={open} as={Fragment}>
                <Dialog as="div" className="fixed inset-0 flex z-40" onClose={setOpen}>
                    <Transition.Child
                        as={Fragment}
                        enter="transition-opacity ease-linear duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-opacity ease-linear duration-300"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-25" />
                    </Transition.Child>

                    {/* drawer */}
                    <Transition.Child
                        as={Fragment}
                        enter="transition ease-in-out duration-300 transform"
                        enterFrom="-translate-x-full"
                        enterTo="translate-x-0"
                        leave="transition ease-in-out duration-300 transform"
                        leaveFrom="translate-x-0"
                        leaveTo="-translate-x-full"
                    >
                        <div className="relative w-full max-w-xs  bg-white shadow-xl pb-12 flex flex-col overflow-y-auto z-50">
                            <div className="px-4 pt-5 pb-2 flex space-x-4">
                                <button
                                    type="button"
                                    className="-m-2 p-2 rounded-md inline-flex items-center justify-center text-gray-400 focus:outline-none focus:ring-secondary focus:border-secondary focus:z-10"
                                    onClick={closeMenu}
                                >
                                    <span className="sr-only">Close menu</span>
                                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                </button>
                                <div>
                                    <img className="block h-8 w-auto" src={logo} alt="Lewoo" />
                                </div>
                            </div>

                            {getCookie("token") && (
                                <div className="px-4 pt-5 pb-2 flex items-center justify-center flex-col space-x-4 text-sm font-bold">
                                    <Avatar
                                        size={80}
                                        round={true}
                                        name={getCookie("name")}
                                        className="cursor-pointer"
                                    />
                                    <span>{getCookie("name")}</span>
                                    <span>{getCookie("email")}</span>
                                </div>
                            )}

                            <div className="border-t border-gray-200 py-6 px-4 space-y-6">
                                <div className="h-full gap-8 items-center">
                                    {pages.map((page) => (
                                        <div key={page.name} className="py-3">
                                            <NavLink
                                                onClick={closeMenu}
                                                key={page.name}
                                                to={`${intl.formatMessage({ id: page.href })}`}
                                                className={`text-base font-semibold block ${location.pathname ===
                                                        intl.formatMessage({ id: page.href })
                                                        ? "border-secondary border-b-2"
                                                        : "hover:text-light"
                                                    }`}
                                            >
                                                <FormattedMessage
                                                    id={page.id}
                                                    defaultMessage={page.name}
                                                />
                                            </NavLink>
                                        </div>
                                    ))}
                                </div>
                            </div>

                            {/* {getCookie("token") && (
                                <div className="border-t border-gray-200 py-6 px-4 space-y-6">
                                    <div className="h-full gap-8 items-center">
                                        {pageSignIn.map((page) => (
                                            <div key={page.name} className="py-3">
                                                <NavLink
                                                    onClick={closeMenu}
                                                    key={page.name}
                                                    to={page.href}
                                                    className={`text-base font-semibold block ${location.pathname === page.href
                                                            ? "border-secondary border-b-2"
                                                            : "hover:text-light"
                                                        }`}
                                                >
                                                    <FormattedMessage
                                                        id={page.id}
                                                        defaultMessage={page.name}
                                                    />
                                                </NavLink>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )} */}
                            {showRightLink && (
                                <div className="border-t border-gray-200 py-6 px-4 space-y-6">
                                    <div className="flex items-center">
                                        <div className="w-full grid gap-6 grid-cols-1">
                                            <SignInLink isMobile={true} closeMenu={closeMenu}/>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {getCookie("token") && (
                                <div className="px-4">
                                    <button
                                        type="button"
                                        className="bg-white rounded-md  flex items-center text-base font-semibold  hover:text-light"
                                        onClick={() => onLogOut()}
                                    >
                                        <FormattedMessage id="logout" />
                                    </button>
                                </div>
                            )}
                        </div>
                    </Transition.Child>
                </Dialog>
            </Transition.Root>
        </Fragment>
    );
};

export default MobileMenu;
