import { UPDATE_HOME } from "../constants";

const initialState = null;

export const setHomeData = (data) => ({
    type: UPDATE_HOME,
    data,
});

export const home = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_HOME:
            return action.data;
        default:
            return state;
    }
};
