import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { NavLink } from "react-router-dom";
import { urlFor } from "../../api/sanity/utils";
import { useHome } from "../../redux/hooks/hooks";
import { logo } from "../../assets/images";

const TechHome = () => {
    const dataHome = useHome();
    const intl = useIntl();

    return (
        <>
            <div className="bg-brand-dark">
                <h1 className=" pt-14 text-3xl font-semibold sm:px-8 sm:text-5xl md:font-extrabold text-center text-secondary-lighter">
                    {dataHome?.modernIot}
                </h1>
                <div className="grid grid-cols-1 md:grid-cols-2 space-x-2 mx-10 mt-14 pb-20">
                    <div className="mx-1 md:mx-8">
                        <h1 className="text-white text-center text-3xl sm:text-4xl font-extrabold">
                            {dataHome?.iotDevices}
                        </h1>
                        <div className="py-1 mt-4">
                            <p className="text-gray-400 text-xl font-lg tracking-normal text-justify">
                                {dataHome?.lewooTrackUtilizes}
                            </p>
                        </div>
                        <div className="flex items-center justify-center text-center my-5">
                            <NavLink
                                to={`${intl.formatMessage({ id: 'link.contact.us' })}`}
                                className="bg-light hover:bg-hover-light text-white font-bold py-3 px-5 rounded-md"
                            >
                                <FormattedMessage id="contact.us" defaultMessage="Contact Us" />
                            </NavLink>
                        </div>
                    </div>
                    <div
                        data-aos="fade-up"
                        data-aos-duration="1000"
                        data-aos-easing="ease-in"
                        className="mt-2"
                    >
                        <div className="grid grid-cols-2 sm:grid-cols-2 gap-2">
                            {dataHome?.imageTech ? (
                                dataHome?.imageTech?.map((item, index) => (
                                    <div
                                        key={index}
                                        className="bg-brand-darker flex justify-center rounded-md"
                                    >
                                        <img
                                            src={urlFor(item.image).fit("max").url()}
                                            alt={item.title}
                                            width="80%"
                                            className="px-10 py-10"
                                        />
                                    </div>
                                ))
                            ) : (
                                <img
                                    src={logo}
                                    alt={"Logo"}
                                    width="80%"
                                    className="px-10 py-10"
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default TechHome;
