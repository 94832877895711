import React, { createContext, useContext, useEffect, useState } from "react";
import { IntlProvider } from "react-intl";
import { getLocalStorage, languageStorage } from "../helpers/localStorage";
import { English, French } from "../languages";

export const LanguageContext = createContext({});

let local = navigator.language;
let lang;

if (local === "fr") {
  lang = French;
} else if (local === "en") {
  lang = English;
} else {
  lang = French;
}

// console.log("local --- ", local);

export const LanguageContextProvider = ({ children }) => {
  const lg = getLocalStorage(languageStorage);
  const [messages, setMessages] = useState(lg && lg === "en" ? English : lang);
  const [language, setLanguage] = useState(lg && lg === "en" ? lg : local);

  useEffect(() => {
    updateLanguage();
  }, []);

  const updateLanguage = () => {
    const lg = getLocalStorage(languageStorage);

    if (lg === "en") {
      setMessages(English);
      setLanguage(lg);
    } else if (lg === "fr") {
      setMessages(French);
      setLanguage(lg);
    } else {
      setMessages(French);
      setLanguage("fr");
    }
  };

  // console.log("language ------ ", language);

  const value = {
    messages,
    language,
    updateLanguage,
  };

  return (
    <LanguageContext.Provider value={value}>
      <IntlProvider messages={messages} locale={language}>
        {children}
      </IntlProvider>
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => useContext(LanguageContext);
