import React from "react";
import ScrollToTop from "react-scroll-to-top";
import { ChevronUp } from "../../assets/images";

const CustomScrollTop = () => {
    return (
        <ScrollToTop
            smooth
            viewBox="0 0 24 24"
            component={<ChevronUp />}
            className="flex w-100 items-center justify-center"
        />
    );
};

export default CustomScrollTop;
