import React from "react";
import { createBrowserRouter } from "react-router-dom";
import {
    About,
    ActivityDetails,
    App,
    Client,
    ContactUs,
    ErrorPage,
    Feature,
    FollowUs,
    Home,
    MyAccount,
    Product,
    SignIn,
    SignUp,
    Subcription,
    UnknowErrorPage,
} from "../pages";
import {
    AboutContent,
    DetailsFollowusContent,
    FollowusActivities,
    FollowusContent,
} from "../components";

const router = createBrowserRouter([
    {
        path: "/",
        element: <App />,
        // errorElement: <ErrorPage />,
        ErrorBoundary: () => <UnknowErrorPage />,
        children: [
            {
                index: true,
                element: <Home />,
            },
            {
                path: "about",
                element: <About />,
                errorElement: <ErrorPage />,
                children: [
                    {
                        index: true,
                        element: <AboutContent />,
                    },
                    {
                        path: "subscription",
                        element: <AboutContent />,
                    },
                    {
                        path: "informations",
                        element: <AboutContent />,
                    },
                    {
                        path: "delevery",
                        element: <AboutContent />,
                    },
                    {
                        path: "general-conditions",
                        element: <AboutContent />,
                    },
                ],
            },
            {
                path: "a-propos",
                element: <About />,
                errorElement: <ErrorPage />,
                children: [
                    {
                        index: true,
                        element: <AboutContent />,
                    },
                    {
                        path: "souscription",
                        element: <AboutContent />,
                    },
                    {
                        path: "informations",
                        element: <AboutContent />,
                    },
                    {
                        path: "livraison",
                        element: <AboutContent />,
                    },
                    {
                        path: "conditions-generales",
                        element: <AboutContent />,
                    },
                ],
            },
            {
                path: "features",
                element: <Feature />,
            },
            {
                path: "fonctionnalites",
                element: <Feature />,
            },
            {
                path: "contactus",
                element: <ContactUs />,
            },

            {
                path: "contactez-nous",
                element: <ContactUs />,
            },
            {
                path: "clients",
                element: <Client />,
            },
            {
                path: "customers",
                element: <Client />,
            },
            {
                path: "sign-in",
                element: <SignIn />,
            },
            {
                path: "se-connecter",
                element: <SignIn />,
            },
            {
                path: "sign-up",
                element: <SignUp />,
            },
            {
                path: "s-inscrire",
                element: <SignUp />,
            },
            {
                path: "subscription",
                element: <Subcription />,
            },
            {
                path: "souscription",
                element: <Subcription />,
            },
            {
                path: "products",
                element: <Product />,
            },
            {
                path: "produits",
                element: <Product />,
            },
            {
                path: "*",
                element: <ErrorPage />,
            },
            {
                path: "track-everything",
                element: <FollowUs />,
                errorElement: <ErrorPage />,
                children: [
                    {
                        index: true,
                        element: <FollowusActivities />,
                    },
                    {
                        path: "how-it-is-work",
                        element: <FollowusContent />,
                    },
                    {
                        path: "client-service",
                        element: <FollowusContent />,
                    },
                ],
            },
            {
                path: "suivez-tout",
                element: <FollowUs />,
                errorElement: <ErrorPage />,
                children: [
                    {
                        index: true,
                        element: <FollowusActivities />,
                    },
                    {
                        path: "comment-marche-t-il",
                        element: <FollowusContent />,
                    },
                    {
                        path: "service-client",
                        element: <FollowusContent />,
                    },
                ],
            },
            {
                path: "activity-details/:detailID",
                element: <ActivityDetails />,
                errorElement: <ErrorPage />,
                children: [
                    {
                        index: true,
                        element: <DetailsFollowusContent />,
                    },
                ],
            },
            {
                path: "details-activite/:detailID",
                element: <ActivityDetails />,
                errorElement: <ErrorPage />,
                children: [
                    {
                        index: true,
                        element: <DetailsFollowusContent />,
                    },
                ],
            },
            {
                path: "my-account",
                element: <MyAccount/>,
                errorElement: <ErrorPage />,
                children: [
                    {
                        index: true,
                        element: <DetailsFollowusContent />,
                    },
                ],
            },
        ],
    },
]);

export default router;

// const router = createBrowserRouter(
//     createRoutesFromElements(
//         <Route
//             path="/"
//             element={<App />}
//             ErrorBoundary={() => <UnknowErrorPage />}
//             // errorElement={<ErrorPage />}
//         >
//             <Route errorElement={<ErrorPage />}>
//                 <Route index element={<Home />} />
//                 <Route path="(fr/accueil)(en/about)(about)" element={<About />} />
//             </Route>
//         </Route>
//     )
// );
