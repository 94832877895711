import {
    AdjustmentsVerticalIcon,
    CheckCircleIcon,
    MapPinIcon,
    SquaresPlusIcon,
    TruckIcon,
    UserIcon,
} from "@heroicons/react/24/outline";
import React from "react";
import { useHome } from "../../redux/hooks/hooks";

const FeatureHome = () => {
    const dataHome = useHome();

    const getIcon = (item, index) => {
        switch (index) {
            case 0:
                return (
                    <>
                        <span className="mb-4 inline-block p-3 rounded-lg bg-yellow-100">
                            <AdjustmentsVerticalIcon className="w-10 h-10 text-yellow-600" />
                        </span>
                        <h4 className="mb-2 text-2xl font-bold font-heading text-yellow-600">
                            {item.title}
                        </h4>
                    </>
                );
            case 1:
                return (
                    <>
                        <span className="mb-4 inline-block p-3 rounded bg-sky-100">
                            <MapPinIcon className="w-10 h-10 text-sky-600" />
                        </span>
                        <h4 className="mb-2 text-2xl font-bold font-heading text-sky-600">
                            {item.title}
                        </h4>
                    </>
                );
            case 2:
                return (
                    <>
                        <span className="mb-4 inline-block p-3 rounded-lg bg-pink-100">
                            <UserIcon className="w-10 h-10 text-pink-600" />
                        </span>
                        <h4 className="mb-2 text-2xl font-bold font-heading text-pink-600">
                            {item.title}
                        </h4>
                    </>
                );
            case 3:
                return (
                    <>
                        <span className="mb-4 inline-block p-3 rounded bg-indigo-100">
                            <TruckIcon className="w-10 h-10 text-indigo-600" />
                        </span>
                        <h4 className="mb-2 text-2xl font-bold font-heading text-indigo-600">
                            {item.title}
                        </h4>
                    </>
                );
            default:
                return (
                    <>
                        <span className="mb-4 inline-block p-3 rounded bg-sky-100">
                            <SquaresPlusIcon className="w-10 h-10 text-sky-600" />
                        </span>
                        <h4 className="mb-2 text-2xl font-bold font-heading text-sky-600">
                            {item.title}
                        </h4>
                    </>
                );
        }
    };

    return (
        <>
            <div className="py-18 bg-blueGray-100">
                <div className="container mx-auto px-4">
                    <div className="flex items-center justify-center">
                        <div className="flex lg:w-8/12">
                            <h2 className="text-center text-3xl pt-12 sm:pt-1 sm:text-5xl font-bold mb-12 text-brand">
                                {dataHome?.allYour}
                            </h2>
                        </div>
                    </div>
                    <div
                        className="flex flex-wrap items-center"
                        data-aos="fade-in"
                        data-aos-duration="1500"
                        data-aos-easing="ease-in"
                    >
                        <div className="w-full flex-col flex items-center lg:w-1/2 mb-12 lg:mb-0">
                            <div className="max-w-md text-center lg:mx-auto">
                                <span className="font-bold text-white bg-secondary-light px-4 py-1 rounded-full shadow-md">
                                    {dataHome?.fleetManagement}
                                </span>
                                <h2 className="my-2 text-3xl sm:text-5xl font-bold font-heading text-brand">
                                    {dataHome?.betterFaster}
                                </h2>
                                <p className="mb-6">{dataHome?.aRbust} </p>
                                <ul className="text-gray-500 font-bold">
                                    {dataHome?.insurances &&
                                        dataHome?.insurances?.map((item, index) => (
                                            <li key={index} className="flex mb-4">
                                                <CheckCircleIcon className="mr-2 w-6 h-6 text-secondary-lighter" />
                                                <span>{item}</span>
                                            </li>
                                        ))}
                                </ul>
                            </div>
                        </div>
                        <div
                            className="w-full lg:w-1/2 flex flex-wrap md:-mx-4"
                            data-aos="fade-up"
                            data-aos-duration="300"
                            data-aos-easing="ease-in"
                        >
                            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                                {dataHome?.services &&
                                    dataHome.services?.map((item, index) => (
                                        <div
                                            key={index}
                                            className="mb-8 py-6 pl-6 pr-4 text-center shadow rounded-lg bg-white"
                                        >
                                            {getIcon(item, index)}
                                            <p className="text-justify text-gray-600 leading-6">{item.value}</p>
                                        </div>
                                    ))}
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default FeatureHome;
