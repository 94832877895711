import { UPDATE_CLIENT } from "../constants";

const initialState = null;

export const setClientData = (data) => ({
    type: UPDATE_CLIENT,
    data,
});

export const client = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_CLIENT:
            return action.data;
        default:
            return state;
    }
};
