// import { configureStore } from "@reduxjs/toolkit";
import { applyMiddleware, createStore } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./reducers";

// const configStore = (preloadedState) =>
//     configureStore({
//         reducer: rootReducer,
//         middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk),
//         preloadedState: preloadedState
//     });

const configureStore = () => createStore(rootReducer, applyMiddleware(thunk));

export default configureStore;
