import imageUrlBuilder from "@sanity/image-url";
import { queryHome } from "./query";
import { client } from "./sanity";

const builder = imageUrlBuilder(client);

export function urlFor(source) {
    return builder.image(source);
}

// uses GROQ to query content: https://www.sanity.io/docs/groq
export async function getHome(language) {
    const home = await client.fetch(queryHome(language));
    console.log("home --- ", home);
    return home;
}

export const getContent = async (language, query) => {
    try {
        const data = await client.fetch(query(language));
        // console.log("data --- ", data);
        return data;
    } catch (e) {
        console.log("error ---- ", e);
    }
};
