import React from "react";
import Avatar from "react-avatar";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import { deleteCookie } from "../../helpers/localStorage";
import { setSelectedPackage } from "../../redux/reducers/subscription";

const ItemProductSubscription = ({ product }) => {
    const intl = useIntl();
    const { name, image_base64 } = product;
    const dispatch = useDispatch();
    const onSelectPackage = (e) => {
        e.preventDefault();
        deleteCookie("onSelectProduct");
        dispatch(setSelectedPackage(product.id));
    };

    return (
        <div
            onClick={onSelectPackage}
            className="shadow-xl rounded-xl h-full md:p-8 px-6 py-8 bg-white space-y-8 hover:bg-hover-lighter cursor-pointer"
        >
            <div className="lg:flex lg:flex-row flex-col lg:gap-4 gap-2 items-center justify-center">
                <div className="flex flex-col items-center">
                    {image_base64 ? (
                        <img
                            className="object-cover"
                            height={50}
                            width={50}
                            src={`data:;base64,${image_base64}`}
                            alt={name}
                        />
                    ) : (
                        <Avatar
                            size={85}
                            round={true}
                            name={name}
                            className="cursor-pointer"
                        />
                    )}
                </div>

                <div className="flex flex-col gap-1 items-center">
                    <span className={`font-bold text-xl lg:text-2xl text-black`}>
                        {name}
                    </span>
                    <NavLink
                        to={`${intl.formatMessage({ id: "link.contact.us" })}`}
                        className="inline-flex items-center justify-center px-3 py-1 border border-green-600 text-base font-medium rounded-md text-light bg-white hover:bg-hover-light hover:text-white"
                    >
                        <FormattedMessage
                            id="request.quote"
                            defaultMessage="Request Quote"
                        />
                    </NavLink>
                </div>
            </div>
        </div>
    );
};

export default ItemProductSubscription;
