import React from "react";

const ClientHero = ({ first, second }) => {
    // console.log('second --- ',second)
    return (
        <div className="client-hero-image backdrop-filter backdrop-opacity-80">
            <div className="relative p-6 md:p-20">
                <div
                    data-aos="slide-up"
                    data-aos-duration="8500"
                    data-aos-easing="ease-in"
                    className="block"
                >
                    <p className="font-bold text-3xl md:text-5xl text-center text-white tracking-tight">
                        {first ? first : "---"}
                    </p>
                    <p className="font-bold text-3xl md:text-5xl text-center text-secondary-lighter mt-2">
                        {second ? second : "---"}
                    </p>
                </div>
            </div>
        </div>
    );
};

export default ClientHero;
