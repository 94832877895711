import { PortableText } from "@portabletext/react";
import React from "react";
import { useLocation } from "react-router-dom";
import {
    components,
    getPortableTextFollows,
} from "../../api/sanity/portableText";
import { useFollowus } from "../../redux/hooks/hooks";

const FollowusContent = () => {
    const { pathname } = useLocation();
    const data = useFollowus();
    const portable = getPortableTextFollows(pathname, data);
    // console.log("data --------- 2", portable);
    return (
        <>
            {portable && (
                <div className="sm:px-4 pt-4">
                    {/* <div className="flex justify-center items-center mb-4">
                        <h1 className="sm:text-3xl text-base font-bold">
                            {portable?.title}
                        </h1>
                    </div> */}
                    <div className="text-base p-4 text-justify">
                        <PortableText
                            value={portable?.block}
                            components={components(false)}
                            onMissingComponent={false}
                        />
                    </div>
                </div>
            )}
        </>
    );
};

export default FollowusContent;
