export const pagesFollowus = [
    {
        name: "Activities",
        href: "link.followus.trackeverything",
        id: "activities",
        field: "activities",
    },
    {
        name: "How it is work",
        href: "link.followus.how.it.work",
        id: "how.it.is.work",
        field: "howItIsWork",
    },
    {
        name: "Client service",
        href: "link.followus.client.service",
        id: "client.service",
        field: "clientService",
    },
];
