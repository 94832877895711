import { UPDATE_FOOTER } from "../constants";

const initialState = null;

export const setFooterData = (data) => ({
    type: UPDATE_FOOTER,
    data,
});

export const footer = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_FOOTER:
            return action.data;
        default:
            return state;
    }
};
