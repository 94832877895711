import React from "react";
import { Loader } from "../../components";

const CommonPage = ({ children, data }) => {
    // console.log(data)
    return (
        <div className={data ? "" : "animate-pulse"}>
            {data && data?.slug ? (
                <>{children}</>
            ) : (
                <Loader />
            )}
            {/* {children} */}
        </div>
    );
};

export default CommonPage;
