import React, { useEffect, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import {
    METHOD_POST,
    formDataBody,
    getDataUrl,
    getHeaders,
    odooData,
    subscriptionUrl,
} from "../../api/odoo/api";
import { getCookie, setLocalStorage } from "../../helpers/localStorage";
import { onToastError, onToastSuccess } from "../../helpers/utils";
import { useSelectedPackage } from "../../redux/hooks/hooks";
import Spinner from "../spinner/Spinner";

const FormSubscription = () => {
    const intl = useIntl();
    const onSelectProduct = useSelectedPackage();
    const [loader, setLoader] = useState(false);
    const [loaderData, setLoaderData] = useState(true);
    const [metaData, setMetaData] = useState({});
    const formRef = useRef();
    const [selectedProduct, setSelectedProduct] = useState("choice");

    useEffect(() => {
        odooData(
            getDataUrl,
            METHOD_POST,
            formDataBody({}),
            getHeaders(null),
            onSuccessData,
            onErrorData
        );
    }, []);

    useEffect(() => {
        setSelectedProduct(
            getCookie("onSelectProduct")
                ? getCookie("onSelectProduct")
                : onSelectProduct
                    ? onSelectProduct
                    : "choice"
        );
    }, [onSelectProduct]);

    const onSuccessData = (result) => {
        setLoaderData(false);
        // console.log("result ---- ", result.data);
        setMetaData(result.data);
        setLocalStorage("packages", result.data.packages);
    };

    const onErrorData = (message) => {
        setLoaderData(false);
        onToastError(
            message === "1"
                ? intl.formatMessage({ id: "please.reload.page" })
                : `${message}\n${intl.formatMessage({ id: "please.reload.page" })}`
        );
    };

    const onSuccess = (result) => {
        setLoader(false);
        formRef.current?.reset();
        // console.log("result ---- ", result.data);
        onToastSuccess(`${intl.formatMessage({ id: "sended" })}`);
    };

    const onError = (message) => {
        setLoader(false);
        onToastError(
            message === "1" ? intl.formatMessage({ id: "internal.problem" }) : message
        );
    };

    const isCheck = (
        category,
        requestType,
        productType,
        vehicleMake,
        plateNumber,
        subject,
        question
    ) => {
        if (category === "choice") {
            onToastError(intl.formatMessage({ id: "category.mandatory" }));
            return false;
        }
        if (requestType === "choice") {
            onToastError(intl.formatMessage({ id: "request.type.mandatory" }));
            return false;
        }
        if (productType === "choice") {
            onToastError(intl.formatMessage({ id: "product.mandatory" }));
            return false;
        }
        if (vehicleMake === "choice") {
            onToastError(intl.formatMessage({ id: "vehicle.mandatory" }));
            return false;
        }
        if (!plateNumber || (plateNumber && plateNumber.length <= 0)) {
            onToastError(intl.formatMessage({ id: "plate.number.mandatory" }));
            return false;
        }
        if (!subject || (subject && subject.length <= 0)) {
            onToastError(intl.formatMessage({ id: "subject.mandatory" }));
            return false;
        }
        if (!question || (question && question.length <= 0)) {
            onToastError(intl.formatMessage({ id: "question.mandatory" }));
            return false;
        }
        return true;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const category = metaData?.category?.[0]?.id; //e.target.elements.category.value;
        const requestType = metaData?.type?.[0]?.id; //e.target.elements.requestType.value;
        const productType = e.target.elements.productType.value;
        const vehicleMake = e.target.elements.vehicleMake.value;
        const plateNumber = e.target.elements.plateNumber.value;
        const subject = e.target.elements.subject.value;
        const question = e.target.elements.question.value;

        if (
            isCheck(
                category,
                requestType,
                productType,
                vehicleMake,
                plateNumber,
                subject,
                question
            )
        ) {
            // console.log("ok");
            setLoader(true);
            odooData(
                subscriptionUrl,
                METHOD_POST,
                formDataBody({
                    user_id: getCookie("userId"),
                    category_id: category,
                    type_id: requestType,
                    product_type: productType,
                    vehicle_make: vehicleMake,
                    plate_number: plateNumber,
                    subject: subject,
                    question: question,
                }),
                getHeaders(null),
                onSuccess,
                onError
            );
        }
    };

    return (
        <form
            className="shadow-md rounded-md space-y-6 bg-white px-4 lg:px-8 py-8"
            method="post"
            onSubmit={handleSubmit}
            ref={formRef}
        >
            {/* category */}
            {/* <div className="">
                <label
                    htmlFor="category"
                    className="flex justify-between font-medium text-gray-700"
                >
                    <FormattedMessage id="category*" />
                    {loaderData && <Spinner />}
                </label>
                <div className="mt-2">
                    <select
                        id="category"
                        name="category"
                        className="w-full rounded-md border border-coolGray-300 focus:outline-none focus:ring-secondary focus:border-secondary focus:z-10"
                    >
                        <option value="choice">
                            {intl.formatMessage({ id: "make.your.choice" })}
                        </option>
                        {metaData?.category &&
                            metaData?.category.map((item, index) => (
                                <option key={index} value={item.id}>
                                    {item.name}
                                </option>
                            ))}
                    </select>
                </div>
            </div> */}

            {/* requestType */}
            {/* <div className="">
                <label
                    htmlFor="requestType"
                    className="flex justify-between  font-medium text-gray-700"
                >
                    <FormattedMessage id="type*" />
                    {loaderData && <Spinner />}
                </label>
                <div className="mt-2">
                    <select
                        id="requestType"
                        name="requestType"
                        className="w-full rounded-md border border-coolGray-300 focus:outline-none focus:ring-secondary focus:border-secondary focus:z-10"
                    >
                        <option value="choice">
                            {intl.formatMessage({ id: "make.your.choice" })}
                        </option>
                        {metaData?.type &&
                            metaData?.type.map((item, index) => (
                                <option key={index} value={item.id}>
                                    {item.name}
                                </option>
                            ))}
                    </select>
                </div>
            </div> */}

            {/* productType */}
            <div className="">
                <label
                    htmlFor="productType"
                    className="flex justify-between  font-medium text-gray-700"
                >
                    <FormattedMessage id="product.type*" />
                    {loaderData && <Spinner />}
                </label>
                <div className="mt-2">
                    <select
                        id="productType"
                        value={selectedProduct}
                        onChange={(e) => setSelectedProduct(e.target.value)}
                        name="productType"
                        className="w-full rounded-md border border-coolGray-300 focus:outline-none focus:ring-secondary focus:border-secondary focus:z-10"
                    >
                        <option value="choice">
                            {intl.formatMessage({ id: "make.your.choice" })}
                        </option>
                        {metaData?.packages &&
                            metaData?.packages.map((item, index) => (
                                <option key={index} value={item.id}>
                                    {item.name}
                                </option>
                            ))}
                    </select>
                </div>
            </div>

            {/* vehicleMake */}
            <div className="">
                <label
                    htmlFor="vehicleMake"
                    className="flex justify-between  font-medium text-gray-700"
                >
                    <FormattedMessage id="vehicle.make*" />
                    {loaderData && <Spinner />}
                </label>
                <div className="mt-2">
                    <select
                        id="vehicleMake"
                        name="vehicleMake"
                        className="w-full rounded-md border border-coolGray-300 focus:outline-none focus:ring-secondary focus:border-secondary focus:z-10"
                    >
                        <option value="choice">
                            {intl.formatMessage({ id: "make.your.choice" })}
                        </option>
                        {metaData?.vehicle &&
                            metaData?.vehicle.map((item, index) => (
                                <option key={index} value={item.id}>
                                    {item.name}
                                </option>
                            ))}
                    </select>
                </div>
            </div>

            {/* plateNumber */}
            <div className="">
                <label
                    htmlFor="plateNumber"
                    className="block  font-medium text-gray-700"
                >
                    <FormattedMessage id="plate.number*" />
                </label>
                <div className="mt-2">
                    <input
                        type="text"
                        id="plateNumber"
                        name="plateNumber"
                        autoComplete="off"
                        className="w-full rounded-md border border-coolGray-300 focus:outline-none focus:ring-secondary focus:border-secondary focus:z-10"
                    />
                </div>
            </div>

            {/* Subject */}
            <div className="mt-6">
                <label htmlFor="subject" className="block  font-medium text-gray-700">
                    <FormattedMessage id="subject*" defaultMessage="Subject*" />
                </label>
                <div className="mt-2">
                    <input
                        type="text"
                        id="subject"
                        name="subject"
                        autoComplete="off"
                        className="w-full rounded-md border border-coolGray-300 focus:outline-none focus:ring-secondary focus:border-secondary focus:z-10"
                    />
                </div>
            </div>

            {/* Question */}
            <div className="">
                <label htmlFor="question" className="block  font-medium text-gray-700">
                    <FormattedMessage
                        id="your.question*"
                        defaultMessage="Your Question*"
                    />
                </label>

                <div className="">
                    <textarea
                        name="question"
                        id="question"
                        className="w-full rounded-md border border-coolGray-300 focus:outline-none focus:ring-secondary focus:border-secondary focus:z-10"
                    ></textarea>
                </div>
            </div>

            <div className="md:flex md:justify-end gap-4 items-center">
                <button
                    type="submit"
                    className={`inline-flex justify-center py-3 px-8 border border-transparent shadow-sm  font-medium rounded-md text-white bg-light hover:bg-hover-light focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary-light ${loader ? "disabled:opacity-50" : ""
                        }`}
                    disabled={loader}
                >
                    {loader ? (
                        <div className="flex items-center">
                            <Spinner />
                            {intl.formatMessage({ id: "sending" })}
                        </div>
                    ) : (
                        <FormattedMessage id="send" defaultMessage="Send" />
                    )}
                </button>
            </div>
        </form>
    );
};

export default FormSubscription;
