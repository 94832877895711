import React from "react";

const ContactHero = ({ first, second }) => {
  return (
    <div className="conatct-hero-image">
      <div className="relative p-6 md:p-20">
        <div data-aos="slide-up" data-aos-duration="850">
          <p className="font-bold text-3xl md:text-5xl text-center text-white tracking-tight">
            {first ? first : "--"}
          </p>
          <p className="font-bold text-xl md:text-3xl text-center text-secondary-lighter mt-2">
            {second ?? second}
          </p>
        </div>
      </div>
    </div>
  );
};

export default ContactHero;
