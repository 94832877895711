import React from "react";
import Avatar from "react-avatar";
import { BsCheck } from "react-icons/bs";
import { FormattedMessage, useIntl } from "react-intl";
import { NavLink, useNavigate } from "react-router-dom";
import { onSubscription } from "../../helpers/utils";

const Product = ({ product }) => {
    const intl = useIntl();
    const { name, price, features, image, image_base64, color } = product;
    const navigate = useNavigate();

    return (
        <div className="shadow-xl rounded-xl md:p-8 px-6 py-8 bg-white space-y-8 h-fit">
            <div className="md:flex md:flex-row flex-col gap-4 items-center justify-center">
                <div className="flex flex-col items-center">
                    {image_base64 ? (
                        <img
                            className="w-24 h-28"
                            src={`data:;base64,${image_base64}`}
                            alt={name}
                        />
                    ) : (
                        <Avatar
                            size={85}
                            round={true}
                            name={name}
                            className="cursor-pointer"
                        />
                    )}
                </div>

                <div className="flex flex-col gap-1 items-center">
                    <span className={`font-bold text-2xl text-[${color}]`}>{name}</span>
                    <NavLink
                        to={`${intl.formatMessage({ id: "link.contact.us" })}`}
                        className="inline-flex items-center justify-center px-3 py-1 border border-green-600 text-base font-medium rounded-md text-light bg-white hover:bg-hover-light hover:text-white"
                    >
                        <FormattedMessage
                            id="request.quote"
                            defaultMessage="Request Quote"
                        />
                    </NavLink>
                </div>
            </div>
            <div className="space-y-4">
                {features?.map((feature, index) => (
                    <div key={index} className="flex items-center justify-between">
                        <span className="text-base">{feature?.name}</span>

                        <div className={`rounded-md bg-light py-0.5 px-4`}>
                            <BsCheck className="h-6 w-6 text-white font-bold" />
                        </div>
                    </div>
                ))}
            </div>

            <div className="flex flex-col items-center">
                <button
                    onClick={() =>
                        onSubscription(
                            navigate,
                            product?.id,
                            intl.formatMessage({ id: "link.subscription" }),
                            intl.formatMessage({ id: "link.sign.in" })
                        )
                    }
                    className="text-white bg-light hover:bg-hover-light px-4 py-2 rounded-md text-md font-semibold"
                >
                    <FormattedMessage id="subscription" />
                </button>
            </div>
        </div>
    );
};

export default Product;
