import { UPDATE_CONTACTUS } from "../constants";

const initialState = null;

export const setContactusData = (data) => ({
    type: UPDATE_CONTACTUS,
    data,
});

export const contactus = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_CONTACTUS:
            return action.data;
        default:
            return state;
    }
};
