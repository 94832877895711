import { PortableText } from "@portabletext/react";
import React from "react";
import { AiOutlineCloudDownload } from "react-icons/ai";
import { useIntl } from "react-intl";
import { useLocation } from "react-router-dom";
import { components, getPortableText } from "../../api/sanity/portableText";
import { useAbout } from "../../redux/hooks/hooks";

const AboutContent = () => {
    const { pathname } = useLocation();
    const data = useAbout();
    const portable = getPortableText(pathname, data);
    const intl = useIntl();
    // console.log("data --------- 334", portable);
    return (
        <>
            {portable?.file && (
                <div className="flex flex-row justify-end px-8 py-4">
                    <a href={portable?.file} target="_blank"  className="flex flex-row text-md cursor-pointer font-bold gap-2">
                        <AiOutlineCloudDownload size={25} />
                        {intl.formatMessage({ id: "download.file" })}
                    </a>
                </div>
            )}

            <div className="flex h-[32rem] overflow-y-auto overflow-x-hidden">
                <div className="px-4 pt-4">
                    <div className="text-base px-8 pb-4 py-8 text-justify">
                        <PortableText
                            value={portable?.block}
                            components={components(false)}
                            onMissingComponent={false}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default AboutContent;
