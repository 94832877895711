import { PortableText } from "@portabletext/react";
import React from "react";
import { FormattedMessage } from "react-intl";
import { useParams } from "react-router-dom";
import {
    components,
    getPortableActivityDetails,
} from "../../api/sanity/portableText";
import { useFollowus } from "../../redux/hooks/hooks";

const DetailsFollowusContent = () => {
    const data = useFollowus();
    const params = useParams();
    const portable = getPortableActivityDetails(data, params?.detailID);
    // console.log("params ---- ", params);
    // console.log("data ---- ", data);
    // console.log("portable ---- ", portable);

    return (
        <>
            {portable ? (
                <div className="sm:px-4 pt-4">
                    <div className="flex justify-center items-center mb-4">
                        <h1 className="sm:text-3xl text-xl font-bold">
                            {portable?.title}
                        </h1>
                    </div>
                    <div className="text-base p-4 text-justify">
                        <PortableText
                            value={portable?.block}
                            components={components(false)}
                            onMissingComponent={false}
                        />
                    </div>
                </div>
            ) : (
                <div className="h-full w-full flex flex-row items-center justify-center">
                    <div className="flex flex-col gap-4 text-center">
                        <h1 className="font-black uppercase text-2xl lg:text-3xl text-brand">
                            Oops!
                        </h1>
                        <p className="font-medium text-base">
                            <FormattedMessage id="sorry.an.unexpected" />
                        </p>
                    </div>
                </div>
            )}
        </>
    );
};

export default DetailsFollowusContent;
