import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { NavLink, useLocation } from "react-router-dom";
import { urlFor } from "../../api/sanity/utils";
import { logo } from "../../assets/images";
import { pages } from "../../constants/pages";
import { useHome } from "../../redux/hooks/hooks";
import MenuIcon from "./MenuIcon";
import SignInLink from "./SignInLink";

const Nav = () => {
    const location = useLocation();
    const dataHome = useHome();
    const intl = useIntl();
    return (
        <div className="shadow">
            <header className="relative bg-white">
                <nav
                    aria-label="Top"
                    className="max-w-7xl mx-auto px-4 sm:px-4 lg:px-4"
                >
                    <div className="flex py-5 items-center">
                        <div className="flex flex-1 flex-col sm:flex-none">
                            <NavLink to="/">
                                <img
                                    className="block h-10 w-auto"
                                    src={
                                        dataHome?.logo
                                            ? urlFor(dataHome?.logo).fit("max").url()
                                            : logo
                                    }
                                    alt="Lewootrack"
                                />
                            </NavLink>
                        </div>

                        <div className="hidden lg:flex lg:self-stretch pl-12">
                            <div className="flex gap-8 items-center">
                                {pages.map((page) => (
                                    <NavLink
                                        key={page.name}
                                        to={`${intl.formatMessage({ id: page.href })}`}
                                        className={`text-base font-semibold ${location.pathname ===
                                                intl.formatMessage({ id: page.href })
                                                ? "border-secondary text-light border-b-2"
                                                : "hover:text-light"
                                            }`}
                                    >
                                        <FormattedMessage id={page.id} defaultMessage={page.name} />
                                    </NavLink>
                                ))}
                            </div>
                        </div>
                        <div className="hidden sm:flex sm:flex-1 sm:justify-end md:flex md:flex-1  md:justify-end md:gap-4 md:items-end lg:hidden xl:flex xl:flex-1 xl:justify-end xl:gap-4 xl:items-end">
                            <div className="flex gap-4">
                                <SignInLink />
                            </div>
                        </div>
                        <MenuIcon />
                    </div>
                </nav>
            </header>
        </div>
    );
};

export default Nav;
