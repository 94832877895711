import { createClient } from "@sanity/client";

const options = {
    dataset: "production", //
    projectId: "5zia5awo", //
    useCdn: false,
    apiVersion: "2021-10-21",
};

export const client = createClient(options);
