import React from "react";
import { ContactHero, FormContactUs, Map } from "../../components";
import { useContactus } from "../../redux/hooks/hooks";
import CommonPage from "../commonPage/CommonPage";

const ContactUs = () => {
    const data = useContactus();
    return (
        <CommonPage data={data}>
            <ContactHero first={data?.label1} second={data?.label2} />
            <div className="bg-blueGray-100 px-8 py-16">
                <div className="md:flex md:flex-row md:gap-4 xl:px-8">
                    <div className="md:w-1/2">
                        <FormContactUs />
                    </div>
                    <div className="md:w-1/2 pt-16 sm:pt-4">
                        <h1 className="text-center text-3xl md:text-5xl font-bold text-gray-900">
                            {data?.label3}
                        </h1>
                        <p className="mt-4 md:mx-6 text-gray-500 text-lg font-medium">
                            {data?.text1}
                        </p>
                        <div className=" font-semibold  mt-8 md:mx-6">
                            <div className="flex flex-row">
                                <div className="text-gray-500 text-base leading-6 font-normal">
                                    <p>{data?.address}</p>
                                    <p>{data?.street}</p>
                                    <p>{data?.postBox}</p>
                                    <p>{data?.phone}</p>
                                </div>
                            </div>
                            <div className="relative flex flex-row mt-2">
                                <Map lat={data?.location?.lat} lng={data?.location?.lng} adrress={'Afric Systems'}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </CommonPage>
    );
};

export default ContactUs;
