import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { NavLink, useLocation } from "react-router-dom";
import { logo } from "../../assets/images";
import { contactUsLinks, usefulLinks } from "../../constants/footer";
import { useFooter } from "../../redux/hooks/hooks";
import { urlFor } from "../../api/sanity/utils";

const Footer = () => {
    const d = new Date();
    const location = useLocation();
    const data = useFooter();
    const intl = useIntl();

    return (
        <footer className="border-t bg-white">
            <div className="py-12 lg:py-16 md:mx-16">
                <div className="container">
                    <div className="flex flex-wrap max-w-full">
                        <div className="w-full  sm:w-1/2 lg:w-1/3">
                            <div className="flex h-full items-center">
                                <div className="flex flex-col gap-4 items-center w-full h-full pb-2 justify-center">
                                    <img
                                        className="block w-auto h-10"
                                        src={
                                            data?.logo ? urlFor(data?.logo).fit("max").url() : logo
                                        }
                                        alt="Lewoo"
                                    />
                                    <div className="flex font-semibold text-center text-base text-black">
                                        {data?.textFooter}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col items-center w-full  mt-6 sm:mt-0 sm:w-1/2 lg:w-1/3">
                            <h3 className="mb-2 font-semibold text-lg sm:mb-4">
                                <FormattedMessage
                                    id="useful.links"
                                    defaultMessage="Useful links"
                                />
                            </h3>
                            <ul className="text-sm list-none text-center w-full">
                                {usefulLinks.map((item) => (
                                    <li key={item.id} className="my-2 text-base text-black">
                                        <NavLink
                                            to={`${intl.formatMessage({ id: item.href })}`}
                                            className={`rounded-sm text-gray-600 hover:text-light hover:font-semibold focus:outline-none font-medium ${location.pathname ===
                                                intl.formatMessage({ id: item.href }) &&
                                                "border-secondary text-light border-b-2 font-semibold"
                                                }`}
                                        >
                                            <FormattedMessage
                                                id={item.id}
                                                defaultMessage={item.name}
                                            />
                                        </NavLink>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div className="flex flex-col  items-center w-full  mt-6 sm:mt-0 sm:w-1/2 lg:w-1/3">
                            <h3 className="mb-2 font-semibold text-lg sm:mb-4">
                                <FormattedMessage
                                    id="reach.to.us"
                                    defaultMessage="Reach out to us"
                                />
                            </h3>
                            <ul className="text-sm text-center list-none w-full">
                                {contactUsLinks.map((item) => (
                                    <li key={item.id} className="my-2 text-base text-black ">
                                        {item?.isBlank ? (
                                            <a
                                                href="https://support.lewootrack.com/requests"
                                                target="_blank"
                                                rel="noreferrer"
                                                className="rounded-sm text-gray-600 hover:text-brand hover:text-light hover:font-semibold focus:outline-none font-medium"
                                            >
                                                Support
                                            </a>
                                        ) : (
                                            <NavLink
                                                to={`${intl.formatMessage({ id: item.href })}`}
                                                className={`rounded-sm text-gray-600 hover:text-light hover:font-semibold focus:outline-none font-medium ${location.pathname ===
                                                    intl.formatMessage({ id: item.href }) &&
                                                    "border-secondary text-light border-b-2 font-semibold"
                                                    }`}
                                            >
                                                <FormattedMessage
                                                    id={item.id}
                                                    defaultMessage={item.name}
                                                />
                                            </NavLink>
                                        )}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="py-4 bg-gray-100">
                <div className="container">
                    <div className="overflow-x-hidden md:mx-8">
                        <div className="flex flex-wrap">
                            <div className="flex items-center w-full ">
                                <p className="text-sm text-brand font-medium">
                                    &copy; {d.getFullYear()} - Lewootrack {` `}
                                    <FormattedMessage
                                        id="all.rights.rReserved"
                                        defaultMessage="All Rights Reserved"
                                    />
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
