import { useSelector } from "react-redux";

export const useHome = () => useSelector((state) => state.home);
export const useFooter = () => useSelector((state) => state.footer);
export const useStartTrial = () => useSelector((state) => state.startTrial);
export const useAbout = () => useSelector((state) => state.about);
export const useFeature = () => useSelector((state) => state.feature);
export const useClient = () => useSelector((state) => state.client);
export const useFollowus = () => useSelector((state) => state.followus);
export const useContactus = () => useSelector((state) => state.contactus);
export const useSignIn = () => useSelector((state) => state.signin);
export const useSignUp = () => useSelector((state) => state.signup);
export const useSubscription = () => useSelector((state) => state.subscription.data);
export const useSelectedPackage = () => useSelector((state) => state.subscription.selectedPackage);
export const useProduct = () => useSelector((state) => state.product);
