import $ from "jquery";
import React, { useCallback, useEffect } from "react";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { Outlet, useLocation, useNavigation } from "react-router-dom";
import { getContent } from "../../api/sanity/utils";
import {
  CustomScrollTop,
  Footer,
  Nav,
  ScrollToTop,
  StartTrial,
} from "../../components";
import { useLanguage } from "../../context";
import { languageStorage, setLocalStorage } from "../../helpers/localStorage";
import {
  getCurrentLanguage,
  getTitle,
  queryAndSet,
  useDocumentTitle,
} from "../../helpers/utils";
import { setFooterData } from "../../redux/reducers/footer";
import { setStartTrialData } from "../../redux/reducers/startTrial";

const App = () => {
  const navigation = useNavigation();
  const { language, updateLanguage } = useLanguage();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const intl = useIntl();

  const getData = useCallback(async (language, pathname) => {
    const qs = await queryAndSet(pathname);
    if (qs) {
      const data = await getContent(language, qs.query);
      if (data) {
        // console.log("data ---------------------- ", data);
        if (qs?.setData) dispatch(qs.setData(data));
        dispatch(setFooterData(data?.footer));
        dispatch(setStartTrialData(data?.startTrial));
      }
    }
  }, []);

  useEffect(() => {
    const currentLanguage = getCurrentLanguage(pathname, language);
    if (currentLanguage != language) {
      setLocalStorage(languageStorage, currentLanguage);
      updateLanguage();
    }
    getData(currentLanguage, pathname);
  }, [pathname]);

  useEffect(() => {
    if (pathname === "/") {
      getData(language, pathname);
    }
  }, [language, pathname]);

  useDocumentTitle(`Lewootrack | ${getTitle(intl, pathname)}`);

  // $(document).on("contextmenu", function (event) {
  //   //event.preventDefault();
  //   // console.log('evenet -- ',event)
  // });

  return (
    <>
      <ScrollToTop />
      <Nav />
      <div className="sm:hidden font-semibold text-light text-md p-2">
        {getTitle(intl, pathname)}
      </div>
      <div className={navigation.state === "loading" ? "animate-pulse" : ""}>
        <Outlet />
      </div>
      <StartTrial />
      <Footer />
      <CustomScrollTop />
    </>
  );
};
export default App;
