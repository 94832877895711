import { UPDATE_FEATURE } from "../constants";

const initialState = null;

export const setFeatureData = (data) => ({
    type: UPDATE_FEATURE,
    data,
});

export const feature = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_FEATURE:
            return action.data;
        default:
            return state;
    }
};
