import React from "react";
import { car1, car2, marker, suv } from "../../assets/images";

export default function CarAnimation() {
  return (
    <div className="bg">
      <div className="car1 flex items-center flex-col justify-center">
        <img
          src={marker}
          alt="Marker"
          className="animate-bounce absolute -top-1.5"
          width="30"
          height="30"
        />
        <img src={car1} alt="Car" />
      </div>
      <div className="car2 flex items-center flex-col justify-center">
        <img
          src={marker}
          alt="Marker"
          className="animate-bounce absolute -top-1.5"
          width="30"
          height="30"
        />
        <img src={car2} alt="Car" />
      </div>
      <div className="car3 flex items-center flex-col justify-center">
        <img
          src={marker}
          alt="Marker"
          className="animate-bounce absolute -top-1.5"
          width="30"
          height="30"
        />
        <img src={suv} alt="suv" className="suv" />
      </div>
    </div>
  );
}
