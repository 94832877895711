import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { NavLink } from "react-router-dom";
import { useStartTrial } from "../../redux/hooks/hooks";

export default function StartTrial() {
  const data = useStartTrial();
  const intl = useIntl()
  return (
    <div className="bg-brand-dark">
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-10 lg:px-8 md:flex md:items-center md:justify-between">
        <h2 className="text-3xl text-center sm:text-left font-extrabold tracking-tight text-white sm:text-4xl">
          <span className="block">{data?.readyTo}</span>
          <span className="block text-secondary-lighter">
            {" "}
            {data?.startTracking}
          </span>
        </h2>
        <div className="mt-8 flex justify-center md:mt-0 lg:flex-shrink-0 space-x-2">
          <div className="inline-flex rounded-md shadow">
            <NavLink
               to={`${intl.formatMessage({ id: 'link.contact.us' })}`}
              className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-light hover:bg-hover-light"
            >
              <FormattedMessage
                id="request.quote"
                defaultMessage="Request Quote"
              />
            </NavLink>
          </div>
          <div className="ml-3 inline-flex rounded-md shadow">
            <NavLink
              to={`${intl.formatMessage({ id: 'link.features' })}`}
              className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-brand-dark bg-white hover:bg-indigo-50"
            >
              <FormattedMessage id="learn.more" defaultMessage="Learn more" />
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
}
