import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { NavLink, useNavigate } from "react-router-dom";
import { signImage } from "../../assets/images";
import { Spinner } from "../../components";
import { onToastError } from "../../helpers/utils";
import {
    METHOD_POST,
    formDataBody,
    getHeaders,
    odooData,
    registerUrl,
} from "../../api/odoo/api";
import { useSignUp } from "../../redux/hooks/hooks";
import { urlFor } from "../../api/sanity/utils";
import CommonPage from "../commonPage/CommonPage";

const SignUp = () => {
    const data = useSignUp();
    const intl = useIntl();
    const [loader, setLoader] = useState(false);
    const regexPhone = /^(\+|\d)(\d+)$/;
    let history = useNavigate();

    const isCheck = (name, email, phone, password, confrimPassword) => {
        if (!name || (name && name.length <= 0)) {
            onToastError(intl.formatMessage({ id: "the.name.field.is.empty" }));
            return false;
        }
        if (!email || (email && email.length <= 0)) {
            onToastError(intl.formatMessage({ id: "the.email.field.is.empty" }));
            return false;
        }
        if (!phone || (phone && phone.length <= 0)) {
            onToastError(intl.formatMessage({ id: "the.phone.field.is.empty" }));
            return false;
        }
        if (phone && !regexPhone.test(phone)) {
            onToastError(intl.formatMessage({ id: "wrong.number.format" }));
            return false;
        }
        if (!password || (password && password.length <= 0)) {
            onToastError(intl.formatMessage({ id: "the.password.field.is.empty" }));
            return false;
        }
        if (password && !confrimPassword) {
            onToastError(
                intl.formatMessage({ id: "the.confirm.password.field.is.empty" })
            );
            return false;
        }
        if (password && confrimPassword && password !== confrimPassword) {
            onToastError(intl.formatMessage({ id: "confirm.password.have.same" }));
            return false;
        }
        return true;
    };

    const onSuccess = (result) => {
        setLoader(false);
        console.log("result  ------- ", result);
        history("/sign-in", {
            replace: true,
        });
    };

    const onError = (message) => {
        setLoader(false);
        onToastError(
            message === "1" ? intl.formatMessage({ id: "internal.problem" }) : message
        );
    };

    const handleSignUp = (e) => {
        e.preventDefault();
        const name = `${e.target.elements.name.value}`.trim();
        const email = `${e.target.elements.email.value}`.trim();
        const phone = `${e.target.elements.phone.value}`.trim();
        const password = `${e.target.elements.password.value}`.trim();
        const confirmPassword = `${e.target.elements.confirmPassword.value}`.trim();
        const type = e.target.elements.type.value;

        if (isCheck(name, email, phone, password, confirmPassword)) {
            console.log("ok");
            setLoader(true);
            odooData(
                registerUrl,
                METHOD_POST,
                formDataBody({
                    name: name,
                    email: email,
                    phone: phone,
                    password: password,
                    type: type,
                }),
                getHeaders(null),
                onSuccess,
                onError
            );
        }
    };
    return (
        <CommonPage data={data}>
            <div className="bg-gray-100 py-8">
                <div className="flex items-center">
                    <div className="flex-1 h-full max-w-4xl mx-auto overflow-hidden bg-white rounded-lg shadow-2xl">
                        <div className="flex flex-col overflow-y-auto md:flex-row">
                            <div className="h-48 md:h-auto md:w-1/2">
                                <img
                                    className="object-cover w-full h-full"
                                    src={
                                        data?.image
                                            ? urlFor(data?.image).fit("max").url()
                                            : signImage
                                    }
                                    alt={"Lewoo"}
                                />
                            </div>
                            {/* form */}
                            <div className="flex items-center justify-center p-6 md:py-8 sm:p-12 md:w-3/5">
                                <div className="w-full">
                                    <h2 className="text-center text-xl font-extrabold text-gray-900">
                                        {data?.label1}
                                    </h2>
                                    <form
                                        className="mt-8 space-y-6"
                                        onSubmit={handleSignUp}
                                        method="post"
                                    >
                                        <div>
                                            <label htmlFor="type">
                                                <FormattedMessage
                                                    id="what.are.you"
                                                    defaultMessage="What are you ?*"
                                                />
                                            </label>
                                            <select
                                                id="type"
                                                name="type"
                                                className="w-full rounded-md border border-coolGray-300 focus:outline-none focus:ring-secondary focus:border-secondary focus:z-10"
                                            >
                                                <option value="company">
                                                    {intl.formatMessage({ id: "company" })}
                                                </option>
                                                <option value="individual">
                                                    {intl.formatMessage({ id: "individual" })}
                                                </option>
                                            </select>
                                        </div>
                                        <div>
                                            <label htmlFor="userName">
                                                <FormattedMessage id="name" defaultMessage="Name" />
                                            </label>
                                            <input
                                                id="userName"
                                                name="name"
                                                type="text"
                                                autoComplete="off"
                                                className="relative block w-full px-3 py-3 border border-gray-300 placeholder-gray-500 text-gray-900 rounded focus:outline-none focus:ring-secondary focus:border-secondary focus:z-10 "
                                            />
                                        </div>
                                        <div>
                                            <label htmlFor="email">
                                                <FormattedMessage id="email" defaultMessage="Email" />
                                            </label>
                                            <input
                                                id="email"
                                                name="email"
                                                type="email"
                                                autoComplete="off"
                                                className="relative block w-full px-3 py-3 border border-gray-300 placeholder-gray-500 text-gray-900 rounded focus:outline-none focus:ring-secondary focus:border-secondary focus:z-10 "
                                            />
                                        </div>
                                        <div>
                                            <label htmlFor="phone">
                                                <FormattedMessage
                                                    id="phone.number"
                                                    defaultMessage="Phone number"
                                                />
                                            </label>
                                            <input
                                                id="phone"
                                                name="phone"
                                                type="tel"
                                                autoComplete="off"
                                                className="relative block w-full px-3 py-3 border border-gray-300 placeholder-gray-500 text-gray-900 rounded focus:outline-none focus:ring-secondary focus:border-secondary focus:z-10 "
                                            />
                                        </div>
                                        <div className="sm:flex sm:gap-6">
                                            <div className="">
                                                <label htmlFor="password">
                                                    <FormattedMessage
                                                        id="password"
                                                        defaultMessage="Password"
                                                    />
                                                </label>
                                                <input
                                                    id="password"
                                                    name="password"
                                                    type="password"
                                                    autoComplete="current-password"
                                                    className="relative block w-full px-3 py-3 border border-gray-300 placeholder-gray-500 text-gray-900 rounded focus:outline-none focus:ring-secondary focus:border-secondary focus:z-10 "
                                                />
                                            </div>
                                            <div className="mt-6 sm:mt-0">
                                                <label htmlFor="confirmPassword">
                                                    <FormattedMessage
                                                        id="confirm.password"
                                                        defaultMessage="Confirm password"
                                                    />
                                                </label>
                                                <input
                                                    id="confirmPassword"
                                                    name="confirmPassword"
                                                    type="password"
                                                    autoComplete="current-password"
                                                    className="relative block w-full px-3 py-3 border border-gray-300 placeholder-gray-500 text-gray-900 rounded focus:outline-none focus:ring-secondary focus:border-secondary focus:z-10 "
                                                />
                                            </div>
                                        </div>
                                        <div>
                                            <button
                                                type="submit"
                                                className="group relative w-full flex justify-center py-3 px-3 border border-transparent 
                  rounded-md text-white bg-light hover:bg-hover-light"
                                                disabled={loader}
                                            >
                                                {loader && (
                                                    <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                                                        <Spinner />
                                                    </span>
                                                )}
                                                <div>
                                                    <span className="text-base font-medium">
                                                        <FormattedMessage
                                                            id="sign.up"
                                                            defaultMessage="Sign up"
                                                        />
                                                    </span>
                                                </div>
                                            </button>
                                            <div className="flex justify-center mt-2">
                                                {data?.label2} &nbsp;
                                                <NavLink
                                                    to={`${intl.formatMessage({ id: "link.sign.in" })}`}
                                                    className="text-indigo-600"
                                                >
                                                    <FormattedMessage id="sign.in" />
                                                </NavLink>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </CommonPage>
    );
};

export default SignUp;
