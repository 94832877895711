import { UPDATE_START_TRIAL } from "../constants";

const initialState = null;

export const setStartTrialData = (data) => ({
    type: UPDATE_START_TRIAL,
    data,
});

export const startTrial = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_START_TRIAL:
            return action.data;
        default:
            return state;
    }
};
