import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
    ContactHero,
    FormSubscription,
    SubscriptionProduct,
} from "../../components";
import { getCookie } from "../../helpers/localStorage";
import { useSubscription } from "../../redux/hooks/hooks";
import CommonPage from "../commonPage/CommonPage";

const Subcription = () => {
    const data = useSubscription();
    let navigate = useNavigate();

    useEffect(() => {
        if (!getCookie("token") || !getCookie("userId")) {
            navigate("/", {
                replace: true,
            });
        }
    }, []);

    return (
        <CommonPage data={data}>
            <ContactHero first={data?.label1} second={data?.label2} />
            <div className="bg-blueGray-100 px-4 lg:px-8 py-16">
                <div className="md:flex md:fle-row lg:gap-4 gap-2 space-y-8">
                    <div className="w-full md:w-1/2 lg:px-8">
                        <FormSubscription />
                    </div>
                    <div className="w-full md:w-1/2 lg:px-8 space-y-8">
                        <div className="font-semibold  text-3xl text-center">
                            {data?.label3}
                        </div>
                        <SubscriptionProduct />
                    </div>
                </div>
            </div>
        </CommonPage>
    );
};

export default Subcription;
