export const fetcher = async (url, headers, body, method) => {
  try {
    const options = {
      method: method,
      headers: headers,
    };
    if (method === "POST" || method === "PUT") options["body"] = body;
    const response = await fetch(url, options);
    if (!response.ok) {
      return new Error("Failed to connect to server");
    }
    const responseData = await response.json();
    //console.log("res --------------- ", responseData);
    return responseData;
  } catch (error) {
    console.error("Error fetching data:", error);
    return error;
  }
};

export const getData = async (url) => {
  const headers = new Headers();
  //headers.append("api-key", ApiKey);
  //headers.append("token", token);
  return fetcher(url, headers, {}, "GET");
};
