export const queryHome = (language) => {
  return `*[_type == "home" && language=="${language}"][0]{
        "slug":slug.current,
        title,
        "logo":logo->image.asset->url,
        intelligentTracking,
        "features":features[]{title, value},
        allYour,
        fleetManagement,
        betterFaster,
        aRbust,
        "insurances":insurances[],
        "services":services[]{title, value},
        modernIot,
        iotDevices,
        lewooTrackUtilizes,
        "imageTech":imageTech[]->{title,"image":image.asset->url},
        weAim,
        "user":user->{"job":job["${language}"],"avatar":image->image.asset->url},
        "video":video.asset->{playbackId, "videoId":data.id},
        "videoYoutube":videoYoutube.youtubeURL,
        "footer":{"textFooter":textFooter,"logo":logo->image.asset->url},
        "startTrial":{readyTo,startTracking}
        }`;
};

export const queryAbout = (language) => {
  return `*[_type == "about" && language=="${language}"][0]{
        title,
        "slug":slug.current,
        label1,
        label2,
        "footer":*[_type == "home" && language=="${language}"][0]{textFooter,"logo":logo->image.asset->url},
        "startTrial":*[_type == "home" && language=="${language}"][0]{readyTo,startTracking},
        "whatIsLewootrack":whatIsLewootrack->{title,"file":file.asset->url,block},
        "informations":informations->{title,"file":file.asset->url,block},
        "subscription":subscription->{title,"file":file.asset->url,block[]{...,
            _type == 'blocks' => {
            ...,
              children[]{
                ...,
                _type == 'contact' => {
                  ...,
                  "image":image->image
                }
              }
            }
          }
        },
        "delevery":delevery->{title,"file":file.asset->url,block},
        "generalConditons":generalConditions->{title,"file":file.asset->url,block},
        textProvider,
        "providers":providers[],
        "video":video.youtubeURL
      }
      `;
};

export const queryFeature = (language) => {
  return `*[_type == "feature" && language=="${language}"][0]{
        title,
        "slug":slug.current,
        label1,
        label2,
        "footer":*[_type == "home" && language=="${language}"][0]{textFooter,"logo":logo->image.asset->url},
        "startTrial":*[_type == "home" && language=="${language}"][0]{readyTo,startTracking},
        "features":features[]{title,"features":{"features":features[],"image":image->image.asset->url}}
      }
      `;
};

export const queryClient = (language) => {
  return `*[_type == "client" && language=="${language}"][0]{
        title,
        "slug":slug.current,
        label1,
        label2,
        labelClient,
        labelPartner,
        "footer":*[_type == "home" && language=="${language}"][0]{textFooter,"logo":logo->image.asset->url},
        "startTrial":*[_type == "home" && language=="${language}"][0]{readyTo,startTracking},
        "clients":clients[]{title,"image": image->image.asset->url,description,url},
        "partners":partners[]{title,"image": image->image.asset->url,description,url}
      }
      `;
};

export const queryFollowus = (language) => {
  return `*[_type == "followus" && language=="${language}"][0]{
        title,
        "slug":slug.current,
        label1,
        label2,
        "footer":*[_type == "home" && language=="${language}"][0]{textFooter,"logo":logo->image.asset->url},
        "startTrial":*[_type == "home" && language=="${language}"][0]{readyTo,startTracking},
        "activities":activities[]{title,"image": image->image.asset->url,"text":textComplet->{_id,"title":title.${language},"block":block.${language}}},
        "howItIsWork":howItIsWork->{title,block},
        "clientService":clientService->{title,block}
      }
      `;
};

export const queryContactus = (language) => {
  return `*[_type == "contactus" && language=="${language}"][0]{
        title,
        "slug":slug.current,
        label1,
        label2,
        label3,
        text1,
        address,
        street,
        postBox,
        location,
        phone,
        "footer":*[_type == "home" && language=="${language}"][0]{textFooter,"logo":logo->image.asset->url},
        "startTrial":*[_type == "home" && language=="${language}"][0]{readyTo,startTracking},
      }
      `;
};

export const querySignIn = (language) => {
  return `*[_type == "signin" && language=="${language}"][0]{
        title,
        "slug":slug.current,
        label1,
        label2,
        "image":image->image.asset->url,
        "footer":*[_type == "home" && language=="${language}"][0]{textFooter,"logo":logo->image.asset->url},
        "startTrial":*[_type == "home" && language=="${language}"][0]{readyTo,startTracking},
      }
      `;
};

export const querySignUp = (language) => {
  return `*[_type == "signup" && language=="${language}"][0]{
        title,
        "slug":slug.current,
        label1,
        label2,
        "image":image->image.asset->url,
        "footer":*[_type == "home" && language=="${language}"][0]{textFooter,"logo":logo->image.asset->url},
        "startTrial":*[_type == "home" && language=="${language}"][0]{readyTo,startTracking},
      }
      `;
};

export const queryProducts = (language) => {
  return `*[_type == "productPage" && language=="${language}"][0]{
        title,
        "slug":slug.current,
        label1,
        label2,
        "products":products[]->{title,price,color,"image":image->image.asset->url,"features":features[]{title,value}},
        "footer":*[_type == "home" && language=="${language}"][0]{textFooter,"logo":logo->image.asset->url},
        "startTrial":*[_type == "home" && language=="${language}"][0]{readyTo,startTracking},
      }
      `;
};

export const querySubscription = (language) => {
  return `*[_type == "subscription" && language=="${language}"][0]{
        title,
        "slug":slug.current,
        label1,
        label2,
        label3,
        "products":*[_type == "productPage" && language=="${language}"][0].products[]->{title,price,color,"image":image->image.asset->url,"features":features[]{title,value}},
        "footer":*[_type == "home" && language=="${language}"][0]{textFooter,"logo":logo->image.asset->url},
        "startTrial":*[_type == "home" && language=="${language}"][0]{readyTo,startTracking},
      }
      `;
};
