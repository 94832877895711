export const pagesAbout = [
    {
        name: "What is lewootrack",
        href: "link.about.what.is.lewootrack",
        id: "what.is.lewootrack",
        field: "whatIsLewootrack",
    },
    
    {
        name: "Informations",
        href: "link.about.informations",
        id: "informations",
        field: "informations",
    },
    {
        name: "General terms and conditions",
        href: "link.about.general.conditions",
        id: "general.conditions",
        field: "generalConditions",
    },
    {
        name: "Subscription",
        href: "link.about.subscription",
        id: "subscription",
        field: "subscription",
    },
    {
        name: "Delevery",
        href: "link.about.delevery",
        id: "delevery",
        field: "delevery",
    },
    
];
