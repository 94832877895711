import React from "react";
import CommonPage from "../commonPage/CommonPage";
import { useClient } from "../../redux/hooks/hooks";
import { ClientHero, ClientItem } from "../../components";
import { urlFor } from "../../api/sanity/utils";
import { FormattedMessage } from "react-intl";

const Client = () => {
    const data = useClient();
    return (
        <CommonPage data={data}>
            <ClientHero first={data?.label1} second={data?.label2} />
            <div className="bg-blueGray-100 px-8 pb-16">
                <div className="flex flex-row py-16 justify-center">
                    <p className=" text-center text-2xl md:text-4xl text-brand font-bold">
                        {data?.labelClient}
                    </p>
                </div>

                <div
                    className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-5 gap-8 sm:px-8"
                    data-aos="slide-right"
                    data-aos-duration="1000"
                    data-aos-easing="ease-in"
                >
                    {data?.clients &&
                        data?.clients?.map((client, index) => (
                            <ClientItem
                                title={client?.title}
                                image={client?.image}
                                url={client?.url}
                            />
                        ))}
                </div>

                <div className="flex flex-row py-16 justify-center">
                    <p className=" text-center text-2xl md:text-4xl text-brand font-bold">
                        {data?.labelPartner}
                    </p>
                </div>

                <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-5 gap-8 sm:px-8"
                data-aos="slide-left"
                data-aos-duration="1000"
                data-aos-easing="ease-in"
                >
                    {data?.partners &&
                        data?.partners?.map((client, index) => (
                            <ClientItem
                                title={client?.title}
                                image={client?.image}
                                url={client?.url}
                            />
                        ))}
                </div>
            </div>
        </CommonPage>
    );
};

export default Client;
