import MuxPlayer from "@mux/mux-player-react";
import getYouTubeID from "get-youtube-id";
import React from "react";
import YouTube from "react-youtube";
import { urlFor } from "../../api/sanity/utils";
import { avatarUser, logo } from "../../assets/images";
import { useHome } from "../../redux/hooks/hooks";
// import muxBlurHash from "@mux/blurhash";

const UserPresentation = () => {
    const data = useHome();
    // const getBlurHash = async () => {
    //     const playbackId = "3fevCt00ntwf7WxwvBhRo1EZ01IoABwo2d";
    //     const { blurHashBase64, sourceWidth, sourceHeight } = await muxBlurHash(
    //         playbackId
    //     );
    // };

    const onReady = (event) => {
        event.target.pauseVideo();
    };
    const videoId = getYouTubeID(data?.videoYoutube);

    return (
        <>
            <div className="bg-white md:pt-12 relative z-50">
                <div className="bg-dot-1 z-10"></div>
                <div className="bg-dot-2 z-10"></div>
                <div className="flex items-center justify-center mx-auto">
                    <img
                        className="h-12 mt-6"
                        src={data?.logo ? urlFor(data?.logo).fit("max").url() : logo}
                        alt="Lewootrack"
                    />
                </div>
                <div className="grid grid-cols-1 sm:grid-cols-2 my-10 z-50">
                    <div
                        className="py-10"
                        data-aos="slide-right"
                        data-aos-duration="1000"
                    >
                        <div className="flex justify-center items-center flex-col pb-8 mx-auto">
                            <img
                                className="flex justify-center items-center h-16 w-16 rounded-full"
                                src={
                                    data?.user?.avatar
                                        ? urlFor(data?.user?.avatar).fit("max").url()
                                        : avatarUser
                                }
                                alt="Odette TIOMO"
                            />
                            <div className="flex-col flex pt-2 text-center">
                                <div className="text-coolGray-500 text-sm font-semibold">
                                    {data?.user?.job}
                                </div>
                            </div>
                        </div>
                        <div className="flex items-center justify-center mx-auto px-5 md:px-10 lg:px-16">
                            <p className="text-gray-800 text-xl font-medium text-center text-justify propose">
                                {data?.weAim}
                            </p>
                        </div>
                    </div>
                    <div
                        className="z-20 flex items-center py-5  px-4 justify-center"
                        data-aos="slide-left"
                        data-aos-duration="1500"
                    >
                        {/* {data?.video && (
                            <MuxPlayer
                                stream-type="on-demand"
                                playbackId={data?.video.playbackId}
                                metadata={{
                                    video_title: "Presentation",
                                    video_id: data?.video.videoId,
                                    viewer_user_id: "user-id-007",
                                }}
                                title="Lewootrack"
                            />
                        )} */}
                        {data?.videoYoutube && (
                            <div className="w-full">
                                <YouTube
                                    videoId={`${videoId}`}
                                    onReady={onReady}
                                    iframeClassName="w-full h-[21rem]"
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default UserPresentation;
