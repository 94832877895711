import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Spinner } from "..";
import {
    onChangeInputFields,
    onToastError,
    onToastSuccess,
} from "../../helpers/utils";
import {
    METHOD_POST,
    contactUsUrl,
    formDataBody,
    getHeaders,
    odooData,
} from "../../api/odoo/api";

const FormContactUs = () => {
    const [loader, setLoader] = useState(false);

    const intl = useIntl();
    const regexPhone = /^(\+|\d)(\d+)$/;
    const [inputFields, setInputFields] = useState({
        type: "company",
    });

    const isCheck = () => {
        if (
            !inputFields["name"] ||
            (inputFields["name"] &&
                `${inputFields["name"]}`.trim().toString().length <= 0)
        ) {
            onToastError(intl.formatMessage({ id: "the.name.field.is.empty" }));
            return false;
        }

        if (
            !inputFields["email"] ||
            (inputFields["eamil"] &&
                `${inputFields["email"]}`.trim().toString().length <= 0)
        ) {
            onToastError(intl.formatMessage({ id: "the.email.field.is.empty" }));
            return false;
        }

        if (
            !inputFields["phone"] ||
            (inputFields["phone"] &&
                `${inputFields["phone"]}`.trim().toString().length <= 0)
        ) {
            onToastError(intl.formatMessage({ id: "the.phone.field.is.empty" }));
            return false;
        }
        if (
            inputFields["phone"] &&
            !regexPhone.test(`${inputFields["phone"]}`.trim())
        ) {
            onToastError(intl.formatMessage({ id: "wrong.number.format" }));
            return false;
        }
        if (
            !inputFields["subject"] ||
            (inputFields["subject"] &&
                `${inputFields["subject"]}`.trim().toString().length <= 0)
        ) {
            onToastError(intl.formatMessage({ id: "the.subject.field.is.empty" }));
            return false;
        }
        if (
            !inputFields["question"] ||
            (inputFields["question"] &&
                `${inputFields["question"]}`.trim().toString().length <= 0)
        ) {
            onToastError(intl.formatMessage({ id: "the.question.field.is.empty" }));
            return false;
        }
        return true;
    };

    const onClean = () => {
        onChangeInputFields(setInputFields, "name", "");
        onChangeInputFields(setInputFields, "email", "");
        onChangeInputFields(setInputFields, "phone", "");
        onChangeInputFields(setInputFields, "subject", "");
        onChangeInputFields(setInputFields, "question", "");
        onChangeInputFields(setInputFields, "type", "company");
    };

    const onSuccess = (result) => {
        setLoader(false);
        onClean();
        onToastSuccess(intl.formatMessage({ id: "sended" }));
    };

    const onError = (message) => {
        setLoader(false);
        onToastError(
            message === "1" ? intl.formatMessage({ id: "internal.problem" }) : message
        );
    };

    const onSend = () => {
        if (isCheck()) {
            setLoader(true);
            console.log("inputFields --- ", inputFields);
            odooData(
                contactUsUrl,
                METHOD_POST,
                formDataBody({
                    name: inputFields["name"],
                    email: inputFields["email"],
                    phone: inputFields["phone"],
                    subject: inputFields["subject"],
                    question: inputFields["question"],
                    type: inputFields["type"],
                }),
                getHeaders(null),
                onSuccess,
                onError
            );
        }
    };

    return (
        <div className="lg:px-8">
            {/* type */}
            <div className="mt-6">
                <label htmlFor="type" className="block  font-medium text-gray-700">
                    <FormattedMessage id="what.are.you" defaultMessage="what.are.you" />
                </label>
                <div className="mt-2">
                    <select
                        id="type"
                        className="w-full rounded-md border border-coolGray-300 focus:outline-none focus:ring-secondary focus:border-secondary focus:z-10"
                        onChange={(e) =>
                            onChangeInputFields(setInputFields, "type", e.target.value)
                        }
                    >
                        <option value="company">
                            {intl.formatMessage({ id: "company" })}
                        </option>
                        <option value="individual">
                            {intl.formatMessage({ id: "individual" })}
                        </option>
                    </select>
                </div>
            </div>

            {/* Name */}
            <div className="mt-6">
                <label htmlFor="name" className="block  font-medium text-gray-700">
                    <FormattedMessage id="name*" defaultMessage="Name*" />
                </label>
                <div className="mt-2">
                    <input
                        type="text"
                        id="name"
                        value={inputFields["name"] ? inputFields["name"] : ""}
                        onChange={(e) =>
                            onChangeInputFields(setInputFields, "name", e.target.value)
                        }
                        autoComplete="off"
                        className="w-full rounded-md border border-coolGray-300 focus:outline-none focus:ring-secondary focus:border-secondary focus:z-10"
                    />
                </div>
            </div>


            {/* Email */}
            <div className="mt-6">
                <label htmlFor="email" className="block  font-medium text-gray-700">
                    <FormattedMessage id="email*" defaultMessage="Email*" />
                </label>
                <div className="mt-2">
                    <input
                        type="email"
                        id="email"
                        value={inputFields["email"] ? inputFields["email"] : ""}
                        onChange={(e) =>
                            onChangeInputFields(setInputFields, "email", e.target.value)
                        }
                        autoComplete="off"
                        className="w-full rounded-md border border-coolGray-300 focus:outline-none focus:ring-secondary focus:border-secondary focus:z-10"
                    />
                </div>
            </div>

            {/* Phone */}
            <div className="mt-6">
                <label htmlFor="phone" className="block  font-medium text-gray-700">
                    <FormattedMessage id="phone.number*" defaultMessage="Phone number*" />
                </label>
                <div className="mt-2">
                    <input
                        type="tel"
                        id="phone"
                        value={inputFields["phone"] ? inputFields["phone"] : ""}
                        onChange={(e) =>
                            onChangeInputFields(setInputFields, "phone", e.target.value)
                        }
                        autoComplete="off"
                        className="w-full rounded-md border border-coolGray-300 focus:outline-none focus:ring-secondary focus:border-secondary focus:z-10"
                    />
                </div>
            </div>

            {/* Subject */}
            <div className="mt-6">
                <label htmlFor="subject" className="block  font-medium text-gray-700">
                    <FormattedMessage id="subject*" defaultMessage="Subject*" />
                </label>
                <div className="mt-2">
                    <input
                        type="text"
                        id="subject"
                        value={inputFields["subject"] ? inputFields["subject"] : ""}
                        onChange={(e) =>
                            onChangeInputFields(setInputFields, "subject", e.target.value)
                        }
                        autoComplete="off"
                        className="w-full rounded-md border border-coolGray-300 focus:outline-none focus:ring-secondary focus:border-secondary focus:z-10"
                    />
                </div>
            </div>

            {/* Question */}
            <div className="mt-6">
                <label htmlFor="question" className="block  font-medium text-gray-700">
                    <FormattedMessage
                        id="your.question*"
                        defaultMessage="Your Question*"
                    />
                </label>

                <div className="mt-2">
                    <textarea
                        name="question"
                        id="question"
                        onChange={(e) =>
                            onChangeInputFields(setInputFields, "question", e.target.value)
                        }
                        value={inputFields["question"] ? inputFields["question"] : ""}
                        className="w-full rounded-md border border-coolGray-300 focus:outline-none focus:ring-secondary focus:border-secondary focus:z-10"
                    ></textarea>
                </div>
            </div>

            <div className="md:flex mt-6 md:justify-end gap-4 items-center">
                <button
                    onClick={() => onSend()}
                    className={`inline-flex justify-center py-3 px-8 border border-transparent shadow-sm  font-medium rounded-md text-white bg-light hover:bg-hover-light focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary-light ${loader ? "disabled:opacity-50" : ""
                        }`}
                    disabled={loader}
                >
                    {loader ? (
                        <div className="flex items-center">
                            <Spinner />
                            {intl.formatMessage({ id: "sending" })}
                        </div>
                    ) : (
                        <FormattedMessage id="send" defaultMessage="Send" />
                    )}
                </button>
            </div>
        </div>
    );
};

export default FormContactUs;
