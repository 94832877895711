import { home } from "./home";
import { footer } from "./footer";
import { combineReducers } from "redux";
import { startTrial } from "./startTrial";
import {about} from "./about"
import {feature} from "./feature"
import {client} from "./client"
import { followus } from "./followus";
import { contactus } from "./contactus";
import { signin } from "./signin";
import { signup } from "./signup";
import { subscription } from "./subscription";
import { product } from "./product";

const rootReducer = combineReducers({
    home,
    about,
    footer,
    startTrial,
    feature,
    client,
    followus,
    contactus,
    signin,
    signup,
    subscription,
    product
});

export default rootReducer;
