import React from "react";
import { useHome } from "../../redux/hooks/hooks";
import CarAnimation from "./CarAnimation";

const BannerHome = () => {
    const dataHome = useHome();

    return (
        <>
            <div className="relative">
                <div className="pt-16 scene">
                    <div className="mb-10 flex flex-col justify-center items-center">
                        <span className="text-3xl flex flex-col sm:w-11/12 lg:w-8/12 xlw-7/12 sm:text-5xl font-extrabold text-center text-white">
                            {dataHome?.intelligentTracking}
                        </span>
                    </div>
                    <div className="md:flex container mx-auto items-center justify-center sm:w-11/12 md:w-11/12 xl:w-7/12 gap-4 z-10 relative">
                        {dataHome?.features &&
                            dataHome?.features?.map((feature, index) => (
                                <div
                                    key={index}
                                    className="mt-2 sm:flex sm:items-center sm:justify-center bg-white p-5 shadow transform transition-all w-full sm:w-11/12 xl:w-full"
                                >
                                    <div className="text-center sm:mt-0 sm:text-left">
                                        <h3 className="text-base font-medium text-gray-600">
                                            {feature.title}{" "}
                                        </h3>
                                        <p className="text-2xl sm:text-3xl font-bold text-brand">
                                            {feature.value}
                                        </p>
                                    </div>
                                </div>
                            ))}
                    </div>
                    <CarAnimation />
                </div>
            </div>
        </>
    );
};

export default BannerHome;
