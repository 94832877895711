import L from "leaflet";
import "leaflet/dist/leaflet.css";
import React from "react";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
    iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
    iconUrl: require("leaflet/dist/images/marker-icon.png"),
    shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

const Map = ({ adrress, lat, lng }) => {
    return (
        <>
            <MapContainer
                className="h-80 w-full"
                center={{ lat: lat, lng: lng }}
                zoom={35}
                scrollWheelZoom={false}
            >
                <TileLayer
                    // attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <Marker position={{ lat: lat, lng: lng }}>
                    <Popup>{adrress}</Popup>
                </Marker>
                {/* <CircleMarker
                    center={{ lat: lat, lng: lng }}
                    pathOptions={{ color: "red" }}
                    radius={10}
                >
                    <Popup>{adrress}</Popup>
                </CircleMarker> */}
            </MapContainer>
        </>
    );
};

export default Map;
