import React from "react";
import { ContactHero } from "../../components";
import { NavLink } from "react-router-dom";
import { pages } from "../../constants/myAccount";
import { useIntl } from "react-intl";

const MyAccount = () => {
    const intl = useIntl();
    return (
        <>
            <ContactHero
                first={"My Lewootrack Pages"}
                second={"List of my different ressources"}
            />
            <div className="w-full bg-blueGray-100 px-4 xl:px-8 py-8">
                <div className="flex flex-col md:flex-row h-screen">
                    <div className="md:w-[40rem] bg-red-100 p-8">
                        <div className="flex flex-col gap-8">
                            <div className="font-bold text-xl">Documents</div>
                            <div className="flex flex-col">
                                {pages.map((link, index) => (
                                    <NavLink
                                        key={index}
                                        to={`${intl.formatMessage({ id: link.href })}`}
                                        className={`text-md flex flex-row justify-between font-semibold mt-3 border-b-2  hover:border-secondary-light font-medium ${location.pathname ===
                                                intl.formatMessage({ id: link.href })
                                                ? "bg-light hover:bg-hover-light border-light text-white border-2 rounded-sm"
                                                : "hover:text-light hover:border-secondary-light"
                                            }`}
                                    >
                                        <span>{intl.formatMessage({ id: link.id })}</span>
                                        <span>{link.number}</span>
                                    </NavLink>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="w-full bg-red-200">Data</div>
                    <div className="md:w-[40rem] bg-red-300 p-8">Profil</div>
                </div>
            </div>
        </>
    );
};

export default MyAccount;
