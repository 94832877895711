import { useEffect, useRef } from "react";
import { toast } from "react-toastify";
import {
    queryAbout,
    queryClient,
    queryContactus,
    queryFeature,
    queryFollowus,
    queryHome,
    queryProducts,
    querySignIn,
    querySignUp,
    querySubscription,
} from "../api/sanity/query";
import { setAboutData } from "../redux/reducers/about";
import { setClientData } from "../redux/reducers/client";
import { setContactusData } from "../redux/reducers/contactus";
import { setFeatureData } from "../redux/reducers/feature";
import { setFollousData } from "../redux/reducers/followus";
import { setHomeData } from "../redux/reducers/home";
import { setSignInData } from "../redux/reducers/signin";
import { setSignUpData } from "../redux/reducers/signup";
import { setProductsData } from "../redux/reducers/product";
import { setSubscriptionData } from "../redux/reducers/subscription";
import { getCookie, setCookie } from "./localStorage";

export const onChangeInputFields = (setFields, key, text) => {
    setFields((prevFields) => ({
        ...prevFields,
        [key]: text,
    }));
};

export const onToastError = (message) => {
    toast.error(message, {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
    });
};

export const onToastSuccess = (message) => {
    toast.success(message, {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
    });
};

export const useDocumentTitle = (title, prevailOnUnmount = false) => {
    const defaultTitle = useRef(document.title);

    useEffect(() => {
        document.title = title;
    }, [title]);

    useEffect(
        () => () => {
            if (!prevailOnUnmount) {
                document.title = defaultTitle.current;
            }
        },
        []
    );
};

export const onSubscription = (
    navigate,
    product,
    linkSubscription,
    linkSignIn
) => {
    setCookie("onSelectProduct", product);
    if (getCookie("token") && getCookie("userId")) {
        navigate(linkSubscription);
    } else {
        setCookie("locationSign", linkSubscription);
        navigate(linkSignIn);
    }
};

export const getColor = (color) => {
    // console.log("color --- ", color);
    return `${color}`.toString().trim().toLowerCase();
};

export const getUrlOpposite = (url) => {
    console.log("url -- ", url);
    switch (url) {
        case "/about":
            return "/a-propos";

        case "/about/subscription":
            return "/a-propos/souscription";
        case "/about/informations":
            return "/a-propos/informations";
        case "/about/delevery":
            return "/a-propos/livraison";
        case "/about/general-conditions":
            return "/a-propos/conditions-generales";

        case "/a-propos":
            return "/about";

        case "/a-propos/souscription":
            return "/about/subscription";
        case "/a-propos/informations":
            return "/about/informations";
        case "/a-propos/livraison":
            return "/about/delevery";
        case "/a-propos/conditions-generales":
            return "/about/general-conditions";

        case "/products":
            return "/produits";
        case "/produits":
            return "/products";
        case "/features":
            return "/fonctionnalites";
        case "/fonctionnalites":
            return "/features";
        case "/track-everything":
            return "/suivez-tout";
        case "/suivez-tout":
            return "/track-everything";

        case "/track-everything/how-it-is-work":
            return "/suivez-tout/comment-marche-t-il";
        case "/suivez-tout/comment-marche-t-il":
            return "/track-everything/how-it-is-work";

        case "/track-everything/client-service":
            return "/suivez-tout/service-client";
        case "/suivez-tout/service-client":
            return "/track-everything/client-service";

        case "/contactus":
            return "/contactez-nous";
        case "/contactez-nous":
            return "/contactus";

        case "/sign-in":
            return "/se-connecter";
        case "/se-connecter":
            return "/sign-in";

        case "/sign-up":
            return "/s-inscrire";
        case "/s-inscrire":
            return "/sign-up";

        case "/subscription":
            return "/souscription";
        case "/souscription":
            return "/subscription";

        case "/products":
            return "/produits";
        case "/produits":
            return "/products";

        case "/clients":
            return "/customers";
        case "/customers":
            return "/clients";

        case "activity-details/:detailID":
            return "details-activite/:detailID";
        case "details-activite-/:detailID":
            return "activity-details/:detailID";

        default:
            return url;
    }
};

export const getUrlOppositeActivityDetails = (url) => {
    console.log("url -- ", url);
    console.log("url -- split", url.split('/')[1]);

    if (`${url}`.includes("activity-details")) {
        return `details-activite/${`${url}`.split("/")[2]}`;
    }
    if (`${url}`.includes("details-activite")) {
        return `activity-details/${`${url}`.split("/")[2]}`;
    }
};

export const getCurrentLanguage = (url, language) => {
    if (url === "/about" && language === "fr") {
        return "en";
    }
    if (url === "/a-propos" && language === "en") {
        return "fr";
    }

    if (url === "/features" && language === "fr") {
        return "en";
    }
    if (url === "/fonctionnalites" && language === "en") {
        return "fr";
    }

    if (url === "/track-everything" && language === "fr") {
        return "en";
    }

    if (url === "/suivez-tout" && language === "en") {
        return "fr";
    }

    if (url === "/about/subscription" && language === "fr") {
        return "en";
    }

    if (url === "/a-propos/souscription" && language === "en") {
        return "fr";
    }

    if (url === "/a-propos/informations" && language === "en") {
        return "fr";
    }

    if (url === "/about/informations" && language === "fr") {
        return "en";
    }

    if (url === "/a-propos/livraison" && language === "en") {
        return "fr";
    }

    if (url === "/about/delevery" && language === "fr") {
        return "en";
    }

    if (url === "/about/general-conditions" && language === "fr") {
        return "en";
    }

    if (url === "/a-propos/conditions-generales" && language === "en") {
        return "fr";
    }

    if (url === "/suivez-tout/comment-marche-t-il" && language === "en") {
        return "fr";
    }

    if (url === "/suivez-tout/service-client" && language === "en") {
        return "fr";
    }

    if (url === "/track-everything/how-it-is-work" && language === "fr") {
        return "en";
    }
    if (url === "/track-everything/client-service" && language === "fr") {
        return "en";
    }
    if (url === "/contactus" && language === "fr") {
        return "en";
    }
    if (url === "/contactez-nous" && language === "en") {
        return "fr";
    }
    if (url === "/se-connecter" && language === "en") {
        return "fr";
    }
    if (url === "/sign-in" && language === "fr") {
        return "en";
    }
    if (url === "/sign-up" && language === "fr") {
        return "en";
    }
    if (url === "/s-inscrire" && language === "en") {
        return "fr";
    }
    if (url === "/subscription" && language === "fr") {
        return "en";
    }
    if (url === "/souscription" && language === "en") {
        return "fr";
    }
    if (url === "/produits" && language === "en") {
        return "fr";
    }
    if (url === "/products" && language === "fr") {
        return "en";
    }
    if (url === "/customers" && language === "fr") {
        return "en";
    }
    if (url === "/clients" && language === "en") {
        return "fr";
    }
    if (`${url}`.includes("activity-details") && language === "fr") {
        return "en";
    }
    if (`${url}`.includes("details-activite") && language === "en") {
        return "fr";
    }

    console.log("language --", language);
    return language;
};

export const queryAndSet = async (url) => {
    // console.log('queryAndSet --------- ',url)
    if (url === "/") {
        return { query: queryHome, setData: setHomeData };
    }
    if (
        url === "/about" ||
        url === "/a-propos" ||
        url === "/about/informations" ||
        url === "/a-propos/informations" ||
        url === "/about/subscription" ||
        url === "/a-propos/souscription" ||
        url === "/a-propos/livraison" ||
        url === "/about/delevery" ||
        url === "/about/general-conditions" ||
        url === "/a-propos/conditions-generales"
    ) {
        return { query: queryAbout, setData: setAboutData };
    }
    if (url === "/features" || url === "/fonctionnalites") {
        return { query: queryFeature, setData: setFeatureData };
    }
    if (url === "/contactus" || url === "/contactez-nous") {
        return { query: queryContactus, setData: setContactusData };
    }
    if (url === "/sign-in" || url === "/se-connecter") {
        return { query: querySignIn, setData: setSignInData };
    }
    if (url === "/sign-up" || url === "/s-inscrire") {
        return { query: querySignUp, setData: setSignUpData };
    }
    if (url === "/products" || url === "/produits") {
        return { query: queryProducts, setData: setProductsData };
    }
    if (url === "/subscription" || url === "/souscription") {
        return { query: querySubscription, setData: setSubscriptionData };
    }
    if (url === "/clients" || url === "/customers") {
        return { query: queryClient, setData: setClientData };
    }

    if (
        url === "/track-everything" ||
        url === "/track-everything/how-it-is-work" ||
        url === "/track-everything/client-service" ||
        url === "/suivez-tout" ||
        url === "/suivez-tout/comment-marche-t-il" ||
        url === "/suivez-tout/service-client"
    ) {
        return { query: queryFollowus, setData: setFollousData };
    }

    if (
        `${url}`.includes("activity-details") ||
        `${url}`.includes("details-activite")
    ) {

        return { query: queryFollowus, setData: setFollousData };
    }
};

export const getTitle = (intl, url) => {
    if (url === "/") {
        return intl.formatMessage({ id: "home" });
    }
    if (url === "/about" || url === "/a-propos") {
        return intl.formatMessage({ id: "about" });
    }
    if (url === "/about/informations" || url === "/a-propos/informations") {
        return intl.formatMessage({ id: "informations" });
    }
    if (url === "/about/subscription" || url === "/a-propos/souscription") {
        return intl.formatMessage({ id: "subscription" });
    }
    if (url === "/a-propos/livraison" || url === "/about/delevery") {
        return intl.formatMessage({ id: "delevery" });
    }
    if (
        url === "/about/general-conditions" ||
        url === "/a-propos/conditions-generales"
    ) {
        return intl.formatMessage({ id: "general.conditions" });
    }
    if (url === "/features" || url === "/fonctionnalites") {
        return intl.formatMessage({ id: "features" });
    }
    if (url === "/contactus" || url === "/contactez-nous") {
        return intl.formatMessage({ id: "contact.us" });
    }
    if (url === "/clients" || url === "/customers") {
        return intl.formatMessage({ id: "clients" });
    }
    if (url === "/track-everything" || url === "/suivez-tout") {
        return intl.formatMessage({ id: "track.everything" });
    }
    if (
        url === "/track-everything/how-it-is-work" ||
        url === "/suivez-tout/comment-marche-t-il"
    ) {
        return intl.formatMessage({ id: "how.it.is.work" });
    }
    if (
        url === "/track-everything/client-service" ||
        url === "/suivez-tout/service-client"
    ) {
        return intl.formatMessage({ id: "client.service" });
    }

    if (url === "/sign-in" || url === "/se-connecter") {
        return intl.formatMessage({ id: "sign.in" });
    }
    if (url === "/sign-up" || url === "/s-inscrire") {
        return intl.formatMessage({ id: "sign.up" });
    }

    if (url === "/subscription" || url === "/souscription") {
        return intl.formatMessage({ id: "subscription" });
    }
    if (url === "/products" || url === "/produits") {
        return intl.formatMessage({ id: "products" });
    }
    if (
        `${url}`.includes("details-activite") ||
        `${url}`.includes("activity-details")
    ) {
        return intl.formatMessage({ id: "activity.details" });
    }

    return "";
};
