import Cookies from "js-cookie";
import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { NavLink, useNavigate } from "react-router-dom";
import {
    DATABASE,
    METHOD_POST,
    formDataBody,
    getHeaders,
    loginUrl,
    odooData,
} from "../../api/odoo/api";
import { urlFor } from "../../api/sanity/utils";
import { signImage } from "../../assets/images";
import { Spinner } from "../../components";
import { getCookie, setAuthentication } from "../../helpers/localStorage";
import { onToastError } from "../../helpers/utils";
import { useSignIn } from "../../redux/hooks/hooks";
import CommonPage from "../commonPage/CommonPage";

const SignIn = () => {
    const data = useSignIn();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [loader, setLoader] = useState(false);
    let history = useNavigate();
    const intl = useIntl();

    const onSuccess = (result) => {
        setLoader(false);
        // console.log("result  ------- ", result);
        setAuthentication(result);
        if (getCookie("locationSign")) {
            // console.log("result  ------- ", getCookie("locationSign"));
            history(`${getCookie("locationSign")}`, {
                replace: true,
            });
            Cookies.remove("locationSign");
        } else {
            history("/", {
                replace: true,
            });
        }

        // window.location.reload();
    };

    const onError = (message) => {
        setLoader(false);
        onToastError(
            message === "1"
                ? intl.formatMessage({ id: "internal.problem" })
                : intl.formatMessage({ id: "access.denied" })
        );
    };

    const isCheck = () => {
        if (!email || (email && `${email}`.trim().toString().length <= 0)) {
            onToastError(intl.formatMessage({ id: "the.email.field.is.empty" }));
            return false;
        }
        if (
            !password ||
            (password && `${password}`.trim().toString().length <= 0)
        ) {
            onToastError(intl.formatMessage({ id: "the.password.field.is.empty" }));
            return false;
        }
        return true;
    };

    const handleSignIn = (e) => {
        e.preventDefault();
        if (isCheck()) {
            setLoader(true);
            odooData(
                loginUrl,
                METHOD_POST,
                formDataBody({
                    login: email,
                    password: password,
                    db: DATABASE,
                }),
                getHeaders(null),
                onSuccess,
                onError
            );
        }
    };

    return (
        <CommonPage data={data}>
            <div className="bg-gray-100 py-8">
                <div className="flex items-center">
                    <div className="flex-1 h-full max-w-4xl mx-auto overflow-hidden bg-white rounded-lg shadow-2xl">
                        <div className="flex flex-col overflow-y-auto md:flex-row">
                            <div className="h-48 md:h-auto md:w-1/2">
                                <img
                                    className="object-cover w-full h-full"
                                    src={
                                        data?.image
                                            ? urlFor(data?.image).fit("max").url()
                                            : signImage
                                    }
                                    alt={"Lewoo"}
                                />
                            </div>
                            {/* form */}
                            <div className="flex items-center justify-center p-6 md:py-14 sm:p-12 md:w-3/5">
                                <div className="w-full">
                                    <h2 className="text-center text-xl font-extrabold text-gray-900">
                                        {data?.label1}
                                    </h2>
                                    <form className="mt-8 space-y-6" onSubmit={handleSignIn}>
                                        <div>
                                            <label htmlFor="email">
                                                <FormattedMessage id="email" defaultMessage="Email" />
                                            </label>
                                            <input
                                                id="email"
                                                name="email"
                                                type="email"
                                                defaultValue={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                                autoComplete="email"
                                                className="relative block w-full px-3 py-3 border border-gray-300 placeholder-gray-500 text-gray-900 rounded focus:outline-none focus:ring-secondary focus:border-secondary focus:z-10 sm:text-sm"
                                            />
                                        </div>
                                        <div className="col-span-6 sm:col-span-3">
                                            <label htmlFor="password">
                                                <FormattedMessage
                                                    id="password"
                                                    defaultMessage="Password"
                                                />
                                            </label>
                                            <input
                                                id="password"
                                                name="password"
                                                type="password"
                                                autoComplete="current-password"
                                                defaultValue={password}
                                                className="relative block w-full px-3 py-3 border border-gray-300 placeholder-gray-500 text-gray-900 rounded focus:outline-none focus:ring-secondary focus:border-secondary focus:z-10 sm:text-sm"
                                                onChange={(e) => setPassword(e.target.value)}
                                            />
                                        </div>
                                        <div>
                                            <button
                                                type="submit"
                                                className="group relative w-full flex justify-center py-3 px-3 border border-transparent 
                                  rounded-md text-white bg-light hover:bg-hover-light"
                                                disabled={loader}
                                            >
                                                {loader && (
                                                    <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                                                        <Spinner />
                                                    </span>
                                                )}
                                                <div>
                                                    <span className="text-base font-medium">
                                                        <FormattedMessage
                                                            id="sign.in"
                                                            defaultMessage="Sign in"
                                                        />
                                                    </span>
                                                </div>
                                            </button>
                                            <div className="flex justify-center mt-2">
                                                {data?.label2} &nbsp;
                                                <NavLink
                                                    to={`${intl.formatMessage({ id: "link.sign.up" })}`}
                                                    className="text-indigo-600"
                                                >
                                                    <FormattedMessage id="sign.up" />
                                                </NavLink>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </CommonPage>
    );
};

export default SignIn;
