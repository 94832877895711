import React from "react";
import { useIntl } from "react-intl";
import { NavLink, Outlet } from "react-router-dom";
import { ClientHero } from "../../components";
import { pagesFollowus } from "../../constants/followus";
import CommonPage from "../commonPage/CommonPage";
import { useFollowus } from "../../redux/hooks/hooks";

const FollowUs = () => {
    const data = useFollowus();
    const intl = useIntl();

    return (
        <CommonPage data={data}>
            <ClientHero first={data?.label1} second={data?.label2} />
            <div className="w-full bg-blueGray-100">
                <div className="w-full mx-auto  sm:px-4 md:px-8 lg:px-14 xl:px-24">
                    <div className="pt-16 gap-4 sm:flex sm:px-4 w-full md:gap-10">
                        <div className="px-4 sm:px-0 sticky sm:top-5 h-1/5 grid gap-2 grip-cols-2 sm:flex w-full sm:w-96 flex-col">
                            {pagesFollowus.map((about, index) => (
                                <NavLink
                                    key={index}
                                    to={`${intl.formatMessage({ id: about.href })}`}
                                    className={`text-base text-center mt-3 border-b-2 hover:border-secondary-light font-medium ${location.pathname === intl.formatMessage({ id: about.href })
                                            ? "bg-light hover:bg-hover-light border-light text-white border-2 rounded-sm"
                                            : "hover:text-light hover:border hover:border-secondary-light"
                                        }`}
                                >
                                    {intl.formatMessage({ id: about.id })}
                                </NavLink>
                            ))}
                        </div>
                        <div className="flex sm:w-full justify-center sm:justify-start h-screen p-4 sm:p-8  mx-4 mt-4 sm:mt-0 sm:mx-0 overflow-y-auto overflow-x-hidden border-2 mb-4 rounded-md">
                            <Outlet />
                        </div>
                    </div>
                    {/* <AboutMobile /> */}
                </div>
            </div>
        </CommonPage>
    );
};

export default FollowUs;
