import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import useSWR from "swr";
import { ProductItem, Spinner } from "..";
import { getPackages } from "../../api/odoo/api";
import { getData } from "../../api/swr";
import { onToastError } from "../../helpers/utils";

const Packages = () => {
  const intl = useIntl();
  const { data, error, isLoading } = useSWR(getPackages, getData);
  const [packages, setPackages] = useState([]);

  useEffect(() => {
    if (data?.data) {
      setPackages(data?.data);
    }
    if (data?.message) {
      onErrorData(data?.message);
    }
  }, [data]);

  const onErrorData = (message) => {
    onToastError(
      `${message}\n${intl.formatMessage({ id: "please.reload.page" })}`
    );
  };

  if (error)
    return (
      <div className="flex h-screen items-center justify-center">
        {intl.formatMessage({ id: "failed.to.load" })}
      </div>
    );
  if (isLoading)
    return (
      <div className="flex h-screen items-center justify-center">
        <Spinner />
      </div>
    );
  return (
    <>
      {packages && packages.length > 0 ? (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {packages?.map((product, index) => (
            <ProductItem key={index} product={product} />
          ))}
        </div>
      ) : (
        <div className="flex h-screen items-center justify-center">
          <span className="font-semibold text-gray-900">Oops!</span>
        </div>
      )}
    </>
  );
};

export default Packages;
