export const usefulLinks = [
    { name: "Home", href: "link.home", id: "home" },
    {
        name: "Products",
        href: "link.products",
        id: "products",
    },
    {
        name: "Features",
        href: "link.features",
        id: "features",
    },
    {
        name: "Clients",
        href: "link.clients",
        id: "clients",
    },
];

export const contactUsLinks = [
    { name: "About", href: "link.about", id: "about" },
    {
        name: "Track EveryThing",
        href: "link.track.everything",
        id: "track.everything",
    },
    {
        name: "Support",
        href: "https://support.lewootrack.com/requests",
        id: "Support",
        isBlank: true,
    },
];
