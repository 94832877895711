import React from "react";
import { urlFor } from "../../api/sanity/utils";

const ClientItem = ({ title, image, url }) => {
    return (
        <a
            href={url}
            target="_blank"
            aria-label={title}
            className="flex flex-row items-center justify-center"
        >
            {/* <a > */}
            <img
                className="w-8/12 sm:w-2/3 md:w-1/2"
                src={urlFor(image).fit("max").url()}
                alt={title}
            />
            {/* </a> */}
        </a>
    );
};

export default ClientItem;
