import { UPDATE_SIGNIN } from "../constants";

const initialState = null;

export const setSignInData = (data) => ({
    type: UPDATE_SIGNIN,
    data,
});

export const signin = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_SIGNIN:
            return action.data;
        default:
            return state;
    }
};
