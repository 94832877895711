import { CheckCircleIcon } from "@heroicons/react/24/outline";
import getYouTubeId from "get-youtube-id";
import React from "react";
import YouTube from "react-youtube";
import { useAbout } from "../../redux/hooks/hooks";

const ProvidersAbout = () => {
    const data = useAbout();
    const onReady = (event) => {
        event.target.pauseVideo();
    };
    const videoId = getYouTubeId(data?.video);
    return (
        <div className="lg:flex lg:flex-row gap-8">
            <div className="w-full flex flex-col">
                <p className="mx-4 md:mx-0 text-secondary-lighter font-semibold text-3xl md:text-4xl tracking-normal">
                    {data?.textProvider}
                </p>

                <div className="pt-8 pb-10">
                    {data?.providers &&
                        data?.providers.map((item, index) => (
                            <div key={index} className="flex flex-row space-x-2 md:mx-4 pt-4">
                                <CheckCircleIcon className="h-6 w-6 text-secondary-lighter" />
                                <p className="text-gray-700 text-md font-semibold tracking-normal">
                                    {item}
                                </p>
                            </div>
                        ))}
                </div>
            </div>
            <div className="w-full px-2 flex flex-col">
                <YouTube
                    videoId={`${videoId}`}
                    onReady={onReady}
                    iframeClassName="w-full h-[21rem]"
                />
            </div>
        </div>
    );
};

export default ProvidersAbout;
