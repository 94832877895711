import React, { useState } from "react";
import Viewer from "react-viewer";
import { ClientHero, FeatureComponent } from "../../components";
import { onChangeInputFields } from "../../helpers/utils";
import { useFeature } from "../../redux/hooks/hooks";
import CommonPage from "../commonPage/CommonPage";

const Feature = () => {
    const data = useFeature();
    const [viewer, setViewer] = useState({
        show: false,
        image: "",
    });
    return (
        <CommonPage data={data}>
            <ClientHero first={data?.label1} second={data?.label2} />
            <div className="bg-blueGray-100 px-8 py-16">
                {data &&
                    data?.features &&
                    data?.features?.map((feature, index) => (
                        <FeatureComponent
                            data={feature}
                            key={index}
                            setViewer={setViewer}
                        />
                    ))}
            </div>
            <Viewer
                visible={viewer?.show}
                onClose={() => onChangeInputFields(setViewer, "show", false)}
                images={[{ src: viewer.image }]}
                className="bg-light opacity-0"
            />
        </CommonPage>
    );
};

export default Feature;
