import { UPDATE_SUBSCRIPTION, SELECTEDPACKAGE } from "../constants";

const initialState = {
    data: null,
    selectedPackage: null,
};

export const setSubscriptionData = (data) => ({
    type: UPDATE_SUBSCRIPTION,
    data,
});

export const setSelectedPackage = (data) => ({
    type: SELECTEDPACKAGE,
    data,
});

export const subscription = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_SUBSCRIPTION:
            return {
                ...state,
                data: action.data,
            };
        case SELECTEDPACKAGE:
            return {
                ...state,
                selectedPackage: action.data,
            };
        default:
            return state;
    }
};
