import { Disclosure, Transition } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/24/outline";
import { PortableText } from "@portabletext/react";
import React from "react";
import { AiOutlineCloudDownload } from "react-icons/ai";
import { useIntl } from "react-intl";
import { components, getPortableText2 } from "../../api/sanity/portableText";
import { pagesAbout } from "../../constants/about";
import { useAbout } from "../../redux/hooks/hooks";

const AboutMobile = () => {
    const data = useAbout();
    const intl = useIntl();
    return (
        <div className="w-full px-2 pt-16 sm:hidden">
            <div className="mx-auto w-full max-w-md rounded-xl bg-white p-2">
                {pagesAbout.map((page, index) => (
                    <Disclosure key={index}>
                        {({ open }) => (
                            <div className="my-4">
                                <Disclosure.Button className="flex w-full justify-between rounded-lg bg-purple-100 px-4 py-2 text-left text-sm font-medium text-purple-900 hover:bg-purple-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                                    <span className="text-base text-light font-semibold">
                                        {intl.formatMessage({ id: page.id })}
                                    </span>
                                    <ChevronUpIcon
                                        className={`${open ? "rotate-180 transform" : ""
                                            } h-5 w-5 text-purple-500`}
                                    />
                                </Disclosure.Button>
                                <Transition
                                    show={open}
                                    enter="transition duration-1000 ease-out"
                                    enterFrom="transform scale-95 opacity-0"
                                    enterTo="transform scale-100 opacity-100"
                                    leave="transition duration-900 ease-out"
                                    leaveFrom="transform scale-100 opacity-100"
                                    leaveTo="transform scale-95 opacity-0"
                                >
                                    <Disclosure.Panel
                                        static
                                        className="px-4 pt-4 pb-2 text-base text-gray-800 h-[32rem] overflow-y-auto"
                                    >
                                        {getPortableText2(page.field, data)?.file && (
                                            <a
                                                href={getPortableText2(page.field, data)?.file}
                                                target="_blank"
                                                className="flex flex-row justify-end px-8 py-4"
                                            >
                                                <span className="flex flex-row text-md cursor-pointer font-bold gap-2">
                                                    <AiOutlineCloudDownload size={25} />
                                                    {intl.formatMessage({ id: "download.file" })}
                                                </span>
                                            </a>
                                        )}
                                        <PortableText
                                            value={getPortableText2(page.field, data)?.block}
                                            components={components(true)}
                                            onMissingComponent={false}
                                        />
                                    </Disclosure.Panel>
                                </Transition>
                            </div>
                        )}
                    </Disclosure>
                ))}
            </div>
        </div>
    );
};

export default AboutMobile;
