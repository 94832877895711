import { Card, CardActionArea, CardContent, CardMedia } from "@mui/material";
import React from "react";
import { useFollowus } from "../../redux/hooks/hooks";
import { NavLink } from "react-router-dom";
import { useIntl } from "react-intl";

const FollowusActivities = () => {
    const data = useFollowus();
    const intl = useIntl();

    return (
        <div className="flex flex-col">
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 pb-8">
                {data?.activities &&
                    data?.activities?.map((activity, index) => (
                        <NavLink
                            key={index}
                            className="flex"
                            to={`${intl.formatMessage({
                                id: "link.activity.details",
                            })}/${activity?.text["_id"]}`}
                        >
                            <Card className="w-full">
                                <CardActionArea>
                                    <CardMedia
                                        component="img"
                                        alt="green iguana"
                                        image={activity.image}
                                    />
                                    <CardContent>
                                        <span className="pt-0">{activity.title}</span>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </NavLink>
                    ))}
            </div>
        </div>
    );
};

export default FollowusActivities;
