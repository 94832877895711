import React from "react";
import { FormattedMessage } from "react-intl";
import { useRouteError } from "react-router-dom";

const UnknowError = () => {
    const error = useRouteError();
    return (
        <div className="flex flex-col h-screen w-full items-center gap-6 justify-center">
            <h1 className="font-black uppercase text-2xl lg:text-3xl text-brand">
                Oops!
            </h1>
            <p className="font-medium text-base">
                <FormattedMessage id="sorry.an.unexpected" />
            </p>
            <p className="font-medium text-base">
                <i>{error?.statusText || error?.message}</i>
            </p>
        </div>
    );
};

export default UnknowError;
