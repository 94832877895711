export const UPDATE_HOME = 'UPDATE_HOME'
export const UPDATE_ABOUT = 'UPDATE_ABOUT'
export const UPDATE_FEATURE = 'UPDATE_FEATURE'
export const UPDATE_CLIENT = 'UPDATE_CLIENT'
export const UPDATE_FOLLOWUS = 'UPDATE_FOLLOWUS'
export const UPDATE_CONTACTUS = 'UPDATE_CONTACTUS'
export const UPDATE_SIGNIN = 'UPDATE_SIGNIN'
export const UPDATE_SIGNUP = 'UPDATE_SIGNUP'
export const UPDATE_SUBSCRIPTION = 'UPDATE_SUBSCRIPTION'
export const UPDATE_PRODUCT = 'UPDATE_PRODUCT'
export const UPDATE_FOOTER = 'UPDATE_FOOTER'
export const UPDATE_START_TRIAL = 'UPDATE_START_TRIAL'
export const SELECTEDPACKAGE = 'SELECTEDPACKAGE'