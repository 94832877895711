export const pages = [
    { name: "Home", href: "link.home", id: "home" },
    { name: "About", href: "link.about", id: "about" },
    {
        name: "Products",
        href: "link.products",
        id: "products",
    },
    {
        name: "Features",
        href: "link.features",
        id: "features",
    },
    {
        name: "Clients",
        href: "link.clients",
        id: "clients",
    },    
    {
        name: "Track EveryThing",
        href: "link.track.everything",
        id: "track.everything",
    },
];

export const pageSignIn = [
    { id: "my.account", name: "My account", href: "/my-account" },
    {
        id: "my.onboarding",
        name: "My onboarding request",
        href: "/onboarding-requests",
    },
    // { id: "subscription", name: "Subscription", href: "/subscription" },
    // { id: "logout.lower", name: "Logout", href: "/" },
];
