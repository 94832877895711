import { CheckCircleIcon } from "@heroicons/react/24/outline";
import React from "react";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import { urlFor } from "../../api/sanity/utils";
import { onChangeInputFields } from "../../helpers/utils";

const Feature = ({ data, setViewer }) => {
    const { title, features } = data;
    const onClickImage = () => {
        onChangeInputFields(setViewer, "show", true);
        onChangeInputFields(
            setViewer,
            "image",
            urlFor(features?.image).fit("max").url()
        );
    };
    return (
        <div className="md:px-8 gap-8 mt-8">
            <span className="text-3xl flex flex-row justify-center sm:text-5xl text-light text-center">
                {title}
            </span>
            <div className="lg:flex lg:flex-row lg:items-center lg:gap-8 mt-8">
                <div className="flex justify-center">
                    {/* <Zoom>
                        <img
                            // className="w-[35rem]"
                            src={urlFor(features?.image).fit("max").url()}
                            height={500}
                            width={500}
                            alt={title}
                        />
                    </Zoom> */}

                    <img
                        className="cursor-pointer" //w-[35rem]
                        onClick={() => onClickImage()}
                        src={urlFor(features?.image).fit("max").url()}
                        height={500}
                        width={500}
                        alt={title}
                    />
                </div>
                <div className="sm:px-8 py-8">
                    <div className="w-full grid grid-clos-1 sm:grid-cols-2 gap-2 sm:gap-4">
                        {features?.features?.length > 0 &&
                            features?.features?.map((feature, index) => (
                                <div key={index} className="flex flex-row items-center gap-2">
                                    <div>
                                        <CheckCircleIcon className="h-6 w-6 text-brand" />
                                    </div>
                                    <div>
                                        <span className="text-base">{feature}</span>
                                    </div>
                                </div>
                            ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Feature;
