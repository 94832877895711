import React from "react";
import {
    BannerHome,
    FeatureHome,
    TechHome,
    UserPresentation,
} from "../../components";
import { useHome } from "../../redux/hooks/hooks";
import CommonPage from "../commonPage/CommonPage";

const Home = () => {
    const dataHome = useHome();
    return (
        <CommonPage data={dataHome}>
            <BannerHome />
            <FeatureHome />
            <TechHome />
            <UserPresentation />
        </CommonPage>
    );
};

export default Home;
