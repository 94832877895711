import { Disclosure, Transition } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/24/outline";
import React from "react";
import { useIntl } from "react-intl";
import { NavLink, useParams } from "react-router-dom";
import { useFollowus } from "../../redux/hooks/hooks";

const ActivityDetailsMobile = () => {
    const data = useFollowus();
    const intl = useIntl();
    const params = useParams();

    return (
        <div className="w-full">
            <Disclosure>
                {({ open, close }) => (
                    <div className="my-4">
                        <Disclosure.Button className="flex w-full justify-between rounded-lg bg-purple-100 px-4 py-2 text-left text-sm font-medium text-purple-900 hover:bg-purple-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                            <span className="text-xl text-light font-semibold">
                                {intl.formatMessage({ id: "your.activities" })}
                            </span>
                            <ChevronUpIcon
                                className={`${open ? "rotate-180 transform" : ""
                                    } h-5 w-5 text-purple-500 font-semibold`}
                            />
                        </Disclosure.Button>
                        <Transition
                            show={open}
                            enter="transition duration-1000 ease-out"
                            enterFrom="transform scale-95 opacity-0"
                            enterTo="transform scale-100 opacity-100"
                            leave="transition duration-900 ease-out"
                            leaveFrom="transform scale-100 opacity-100"
                            leaveTo="transform scale-95 opacity-0"
                        >
                            <Disclosure.Panel static className="px-4 pt-4 pb-2 flex flex-col">
                                {data?.activities &&
                                    data?.activities.map((about, index) => (
                                        <NavLink
                                            onClick={() => close()}
                                            key={index}
                                            to={`${intl.formatMessage({
                                                id: "link.activity.details",
                                            })}/${about?.text?._id}`}
                                            className={`text-base text-center mt-2 border-b-2 hover:border-secondary-light font-medium ${params?.detailID === about?.text?._id
                                                    ? "bg-light hover:bg-hover-light border-light text-white border-2 rounded-sm"
                                                    : "hover:text-light hover:border hover:border-secondary-light"
                                                }`}
                                        >
                                            {about.title}
                                        </NavLink>
                                    ))}
                            </Disclosure.Panel>
                        </Transition>
                    </div>
                )}
            </Disclosure>
        </div>
    );
};

export default ActivityDetailsMobile;
