import { Bars3Icon } from "@heroicons/react/24/outline";
import React, { useState } from "react";
import MobileMenu from "./MobileMenu";
import { getCookie } from "../../helpers/localStorage";
import Avatar from "react-avatar";

const MenuIcon = () => {
    const token = getCookie("token");
    const [open, setOpen] = useState(false);
    const [showRightLink, setShowRightLink] = useState(false);
    const onOpenMobile = (showRightLink) => {
        console.log("text --- ", showRightLink);
        setShowRightLink(showRightLink);
        setOpen(true);
    };

    return (
        <>
            <div className="flex lg:flex-1 lg:justify-end xl:flex-none">
                {token && (
                    <Avatar
                        size={40}
                        round={true}
                        name={getCookie("name")}
                        textSizeRatio={2}
                        className="cursor-pointer ml-2 hidden xl:block"
                        onClick={() => onOpenMobile(true)}
                    />
                )}
                <button
                    type="button"
                    className="hidden bg-white p-2 rounded-md sm:block lg:hidden"
                    onClick={() => onOpenMobile(false)}
                >
                    <span className="sr-only">Open menu</span>
                    <Bars3Icon className="h-7 w-8" aria-hidden="true" />
                </button>
                <button
                    type="button"
                    className="bg-white p-2 rounded-md  sm:hidden"
                    onClick={() => onOpenMobile(true)}
                >
                    <span className="sr-only">Open menu</span>
                    <Bars3Icon className="h-7 w-8" aria-hidden="true" />
                </button>

                <button
                    type="button"
                    className="bg-white p-2 rounded-md hidden lg:block xl:hidden"
                    onClick={() => onOpenMobile(true)}
                >
                    <span className="sr-only">Open menu</span>
                    <Bars3Icon className="h-7 w-8" aria-hidden="true" />
                </button>
            </div>
            <MobileMenu
                open={open}
                showRightLink={showRightLink}
                setOpen={setOpen}
                closeMenu={() => setOpen(false)}
            />
        </>
    );
};

export default MenuIcon;
