import React from "react";
import { useIntl } from "react-intl";
import { NavLink, Outlet, useParams } from "react-router-dom";
import { ActivityDetailsMobile, ClientHero } from "../../components";
import { useFollowus } from "../../redux/hooks/hooks";
import CommonPage from "../commonPage/CommonPage";

const ActivityDetails = () => {
    const data = useFollowus();
    const intl = useIntl();
    const params = useParams();

    return (
        <CommonPage data={data}>
            <ClientHero first={data?.label1} second={data?.label2} />
            <div className="w-full bg-blueGray-100">
                <div className="w-full mx-auto  sm:px-4 md:px-8 lg:px-16 xl:px-24">
                    <div className="pt-16 gap-4 flex flex-col md:flex-row sm:px-4 w-full md:gap-10">
                        <div className="flex flex-col gap-4 md:w-96 md:pb-8">
                            <div className="w-full text-center">
                                <NavLink
                                    to={`${intl.formatMessage({
                                        id: "link.followus.trackeverything",
                                    })}`}
                                    className={`text-2xl font-semibold text-center hover:text-light`}
                                >
                                    {intl.formatMessage({ id: "activities" })}
                                </NavLink>
                            </div>
                            <div className="md:hidden">
                                <ActivityDetailsMobile />
                            </div>
                            <div className="px-4 hidden sm:px-0 sticky h-1/2 md:h-[48rem]  overflow-y-auto overflow-x-hidden sm:top-5 h-1/5 grid grip-cols-2 gap-2 md:flex w-full flex-col">
                                {data?.activities &&
                                    data?.activities.map((about, index) => (
                                        <NavLink
                                            key={index}
                                            to={`${intl.formatMessage({
                                                id: "link.activity.details",
                                            })}/${about?.text?._id}`}
                                            className={`text-base text-center mt-2 border-b-2 hover:border-secondary-light font-medium ${params?.detailID === about?.text?._id
                                                    ? "bg-light hover:bg-hover-light border-light text-white border-2 rounded-sm"
                                                    : "hover:text-light hover:border hover:border-secondary-light"
                                                }`}
                                        >
                                            {about.title}
                                        </NavLink>
                                    ))}
                            </div>
                        </div>
                        <div className="flex sm:w-full justify-center sm:justify-start h-screen p-4 sm:p-8  mx-4 mt-4 sm:mt-0 sm:mx-0 overflow-y-auto overflow-x-hidden border-2 mb-4 rounded-md">
                            <Outlet />
                        </div>
                    </div>
                    {/* <AboutMobile /> */}
                </div>
            </div>
        </CommonPage>
    );
};

export default ActivityDetails;
