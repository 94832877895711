import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { NavLink, Outlet, useLocation } from "react-router-dom";
import { AboutMobile, FeatureHero, ProvidersAbout } from "../../components";
import { pagesAbout } from "../../constants/about";
import { useAbout } from "../../redux/hooks/hooks";
import CommonPage from "../commonPage/CommonPage";

const About = () => {
    const intl = useIntl();
    const location = useLocation();
    const data = useAbout();
    return (
        <CommonPage data={data}>
            <FeatureHero first={data?.label1} second={data?.label2} />
            <div className="w-full bg-blueGray-100">
                <div className="w-full mx-auto  sm:px-4 md:px-16 lg:px-24">
                    <div className="hidden pt-16 gap-4 sm:flex sm:px-4 w-full md:gap-10">
                        <div className="px-4 sm:px-0 sticky sm:top-5 h-1/5 grid gap-2 grip-cols-2 sm:flex w-full sm:w-96 flex-col">
                            {pagesAbout.map((about, index) => (
                                <NavLink
                                    key={index}
                                    to={`${intl.formatMessage({ id: about.href })}`}
                                    className={`text-base text-center mt-3 border-b-2  hover:border-secondary-light font-medium ${location.pathname === intl.formatMessage({ id: about.href })
                                            ? "bg-light hover:bg-hover-light border-light text-white border-2 rounded-sm"
                                            : "hover:text-light hover:border hover:border-secondary-light"
                                        }`}
                                >
                                    {intl.formatMessage({ id: about.id })}
                                </NavLink>
                            ))}
                        </div>
                        <div className="pt-8 sm:pt-0 px-4 sm:px-0 w-full border-2 mb-4 rounded-md">
                            <Outlet />
                        </div>
                    </div>
                    <AboutMobile />
                </div>
                <div className="w-full mx-auto px-4 sm:px-4 md:px-16 lg:px-24 py-16">
                    <ProvidersAbout />
                </div>
            </div>
        </CommonPage>
    );
};

export default About;
