import React from "react";
import {
    ClientHero,
    Packages
} from "../../components";
import { useProduct } from "../../redux/hooks/hooks";
import CommonPage from "../commonPage/CommonPage";

const Product = () => {
    const data = useProduct();

    return (
        <CommonPage data={data}>
            <ClientHero first={data?.label1} second={data?.label2} />
            <div className="bg-blueGray-100 px-4 md:px-28 xl:px-72 2xl:px-96 py-16">
                <Packages />
            </div>
        </CommonPage>
    );
};

export default Product;
