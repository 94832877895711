export const URL = "https://dev.support.africasystems.com"; //http://localhost:8013/ //https://dev.lewoo.info/  https://admin.africsys.com/  https://dev.support.africasystems.com
export const DATABASE = "support_erp_db"; //lewoo_db_1 afrisyserp as_db_2 aserp_16_neo_db aserp_16_db support_db
export const loginA = "paved.ngoune@africasystems.com";
export const loginUrl = `${URL}/auth/request/lewootrack/token`;
export const registerUrl = `${URL}/lewootrack/register_user`;
export const contactUsUrl = `${URL}/lewootrack/submit_contact_us`;
export const subscriptionUrl = `${URL}/lewootrack/submit_subscription`;
export const getDataUrl = `${URL}/lewootrack/get_data`;
export const getPackages = `${URL}/lewootrack/get_packages`;
export const getPageUrl = `${URL}/lewootrack/my_page`;
export const getQuotationUrl = `${URL}/lewootrack/my_quotation`;
export const getPurchaseOrderUrl = `${URL}/lewootrack/my_purchase_order`;
export const getInvoiceBillsUrl = `${URL}/lewootrack/my_invoice_bill`;
export const getRequestsUrl = `${URL}/lewootrack/requests`;
export const METHOD_POST = "POST";
export const METHOD_GET = "GET";

export const odooFetch = (url, req) => {
    return fetch(url, req);
};

export const odooData = (
    url,
    method,
    formData,
    headers,
    onSuccess,
    onError
) => {
    var req = {
        method: method,
        headers: headers,
    };
    if (method == "POST") req["body"] = formData;
    // console.log('headers -- ', headers)
    // console.log('url -- ', url)
    odooFetch(url, req)
        .then((response) => response.json())
        .then((result) => {
            console.log(result)
            if (result && result.success === 1) {
                if (onSuccess) {
                    onSuccess(result);
                }
            } else {
                if (onError) {
                    onError(result?.message);
                }
            }
        })
        .catch((error) => {
            console.log("error -- ==", error);
            if (onError) {
                onError("1");
            }
        });
};

export const formDataBody = (fields) => {
    var formData = new FormData();
    formData.append("ID", "1");
    Object.keys(fields).forEach((key) => {
        if (fields[key]) {
            formData.append(key, fields[key]);
        }
    });
    // console.log("formData -- ", formData);
    return formData;
};

export const getHeaders = (token) => {
    var myHeaders = new Headers();
    if (token) myHeaders.append("Authorization", "Bearer " + token);
    return myHeaders;
};
