import { UPDATE_PRODUCT } from "../constants";

const initialState = null;

export const setProductsData = (data) => ({
    type: UPDATE_PRODUCT,
    data,
});

export const product = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_PRODUCT:
            return action.data;
        default:
            return state;
    }
};
