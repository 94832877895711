import React, { useEffect, useState } from "react";
import useSWR from "swr";
import { getPackages } from "../../api/odoo/api";
import Spinner from "../spinner/Spinner";
import ItemProductSubscription from "./ItemProductSubscription";
import { getData } from "../../api/swr";

const SubscriptionProduct = () => {
    const { data, isLoading } = useSWR(getPackages, getData);
    const [packages, setPackages] = useState([]);

  useEffect(() => {
    if (data?.data) {
      setPackages(data?.data);
    }
  }, [data]);

    return (
        <>
            {isLoading ? (
                <div className="flex h-screen items-center justify-center">
                    <Spinner />
                </div>
            ) : packages && packages?.length > 0 ? (
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    {packages?.map((product, index) => (
                        <ItemProductSubscription product={product} key={index} />
                    ))}
                </div>
            ) : (
                <div className="flex h-screen items-center justify-center">
                    <span className="font-semibold text-gray-900">Oops!</span>
                </div>
            )}
        </>
    );
};

export default SubscriptionProduct;
